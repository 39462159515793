import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useOrientation } from '../../../Hooks';
import Card from './Card.js';
import { LocaleContext } from '../../../Context/LocaleContext.js';

const useStyles = makeStyles({
  suit: {
    whiteSpace: 'nowrap'
  },
  suitFlipped: {
    display: 'initial !important'
  },
  suitIndex0Portrait: {
    display: 'contents'
  },
  suitIndex2Portrait: {
    display: 'contents'
  },
  index0Stack: {
    display: 'block',
    paddingBottom: '8em'
  },
  index0Portrait: {
  },
  index1Stack: {
    paddingRight: '7em',
    textAlign: 'right'
  },
  nl: {
    '& $card': {
      height: '15.5em'
    }
  },
  card: {
    display: 'inline-block',
    marginRight: '-7em',
    position: 'relative',
    minWidth: 0,
    flexGrow: 1,
    overflow: 'visible',
    transition: 'all 0.15s ease-out',
    textAlign: 'center',
    width: '10em',
    height: '14em',
    lineHeight: 0,
    '& img': {
      width: 'inherit !important',
      border: '1px solid #232a2c',
      borderRadius: '0.6em'
    },
    '&:after': { // card shadow for disabled cards
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      top: '0',
      marginLeft: '-10em',
      width: '10em',
      height: 'calc(14em - 2px)',
      transition: 'opacity 0.25s ease-out',
      borderRadius: '0.5em'
    }
  },
  noMarginRight: {
    marginRight: '0 !important'
  },
  cardInTopStack: {
    display: 'block',
    margin: '0 0.5em -10em 0.5em !important'
  },
  cardInTopStackDutch: {
    display: 'block',
    margin: '0 0.5em -12em 0.5em !important'
  },
  enabled: {
    transform: 'translate3d(0, -1em, 0)',
    minWidth: '10%',
    marginRight: '-5em',
    // boxShadow: '0 0.5em 1em rgba(0,0,0,0.25)',
    '&:hover': {
      transform: 'translate3d(0, -2em, 0)'
      // boxShadow: '0 0.5em 1.5em rgba(0,0,0,0.35)',
    },
    '&:hover img': {
      boxShadow: '0 0 0 0.4em rgba(255,183,58,0.75)'
    },
    '&:after': {
      opacity: 0
    }
  },
  enabledAllCards: {
    minWidth: 0
  },
  enabledIndex0Portrait: {
    transform: 'translate3d(0, 1em, 0)',
    '&:hover': {
      transform: 'translate3d(0, 2em, 0)'
    }
  },
  invisible: {
    display: 'contents',
    visibility: 'hidden',
    width: 0,
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    '& img, &:after': {
      display: 'none'
    }
  }
});

export default function CardSuitGroup (props) {
  const {
    cards,
    onCardClick,
    isStack,
    seatIndex,
    enabledSuit,
    enabled,
    flipped,
    variant,
    cardRefCallback
  } = props;

  const isPortrait = useOrientation();

  const { locale } = useContext(LocaleContext);

  const classes = useStyles({ language: locale.language });
  if (cards.length > 0) {
    return (
      <div className={
        clsx(
          classes.suit,
          flipped && classes.suitFlipped,
          isPortrait && classes[`suitIndex${seatIndex}Portrait`],
          isStack && !isPortrait && classes[`index${seatIndex}Stack`],
          isPortrait && classes[`index${seatIndex}Portrait`],
          variant && classes[variant]
        )
}
      >
        {
        cards.map((card, index) => {
          const cardValue = `${card.rank}${card.suit}`;
          return (
            <div
              className={
                clsx(
                  classes.card,
                  enabled && classes.enabled,
                  enabled && seatIndex === 0 && isPortrait && classes.enabledIndex0Portrait,
                  isPortrait && !flipped && (seatIndex === 0 || seatIndex === 2) && classes.noMarginRight,
                  enabledSuit === 'all' && classes.enabledAllCards,
                  card.visible === false && classes.invisible,
                  isStack && seatIndex === 0 && !isPortrait && (locale.language !== 'nl-nl' ? classes.cardInTopStack : classes.cardInTopStackDutch)
                )
              }
              key={index}
            >
              <Card
                value={cardValue}
                onClick={onCardClick && onCardClick}
                enabled={enabled}
                flipped={flipped}
                variant={variant}
                cardRefCallback={cardRefCallback}
              />
            </div>
          );
        })
      }
      </div>
    );
  } else {
    return null;
  }
}

CardSuitGroup.propTypes = {
  seatIndex: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  flipped: PropTypes.bool.isRequired,
  enabledSuit: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  isStack: PropTypes.bool.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func
};
