import React from 'react';
import { useTheme } from '@mui/styles';
import {
  Box
} from '@mui/material';
import AuthorHeader from '../../../../../Components/Store/AuthorHeader.js';
import AuthorLink from '../../../../../Components/Store/AuthorLink.js';
import Tags from '../../../../../Components/Store/Tags.js';
import SubscriptionChip from '../../../../../Components/Store/Subscriptions/SubscriptionChip.js';
import { getGroupTagsFromTagsCache } from '../../../../../helpers/tag.js';

export default function SubscriptionArticleHeader ({ subscription, isSubscribed }) {
  const theme = useTheme();
  return (
    <AuthorHeader
      title={subscription.title}
      author={subscription.author}
      colour={theme.palette.styleguide.pink}
    >
      <Box sx={{ zIndex: 5 }}>
        <AuthorLink author={subscription.author} noAvatar white />
      </Box>
      <Tags tags={getGroupTagsFromTagsCache(subscription.tagsCache)} white />
      <SubscriptionChip subscribed={isSubscribed} />
    </AuthorHeader>
  );
}
