import gql from 'graphql-tag';
import { articleFragments } from '../fragments';

const articleQueries = {
  ArticleById: (id) => {
    return gql`
      query {
        articleById (
          id: ${id}
        ) {
          ...FullArticle
        }
      }
      ${articleFragments.fullArticle}
    `;
  },
  LatestArticleOrByPublicId: gql`
    query (
      $id: String,
      $subscriptionId: Int
    ) {
      latestArticleOrByPublicId (
        publicId: $id,
        subscriptionId: $subscriptionId
      ) {
        ...FullArticle
      }
    }
    ${articleFragments.fullArticle}
  `,
  ArticlesByUserAndSubscriptionId: gql`
    query ($subscriptionId: Int!) {
      articlesByUserAndSubscriptionId (subscriptionId: $subscriptionId) {
        id
        publicId
        title
        body
        summary
        status
        publishDateTime
        hands {
          id
          title
          canPlay
          freeToPlay
        }
      }
    }
  `
};

export default articleQueries;
