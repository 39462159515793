import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../graphql';
import { useTranslation } from 'react-i18next';
import UserPurchases from '../../../Components/Queries/UserPurchases.js';
import PurchaseTable from '../../../Components/Store/Purchases/PurchaseTable.js';
import SavingBackdrop from '../../../Components/Forms/SavingBackdrop.js';

export default function AccountPurchases () {
  const outletContext = useOutletContext();
  const [resendId, setResendId] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);

  let handleSubmitSuccess, handleSubmitError;
  if (outletContext) {
    ({ handleSubmitSuccess, handleSubmitError } = outletContext);
  }

  const { t } = useTranslation();

  const [resendGiftEmail] = useMutation(mutations.ResendGiftEmail, {
    onCompleted: () => {
      handleSubmitSuccess(t('gift-email-successfully-sent'));
    },
    onError: (err) => {
      handleSubmitError(err);
    }
  });

  const handleSendEmailClick = purchaseItemId => async () => {
    setResendLoading(true);
    setResendId(purchaseItemId);
    await resendGiftEmail({
      variables: {
        purchaseItemId
      }
    });
    setResendLoading(false);
  };

  return (
    <UserPurchases
      loaderComponent={<SavingBackdrop open />}
    >
      {userPurchases => (
        <PurchaseTable
          userPurchases={userPurchases}
          onSendEmailClick={handleSendEmailClick}
          resendId={resendId}
          resendLoading={resendLoading}
        />
      )}
    </UserPurchases>
  );
}
