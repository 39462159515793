import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  articleQueries,
  subscriptionQueries
} from '../../../../graphql/queries';
import {
  Box,
  Grid,
  Typography,
  Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArticleHandList from './Components/ArticleHandList.js';
import ArticleSkeleton from './Components/ArticleSkeleton.js';
import SubscriptionArticleHeader from './Components/SubscriptionArticleHeader.js';
import LocalisedDisplayDate from '../../../../Components/Locale/LocalisedDisplayDate.js';
import { UserContext } from '../../../../Context';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';
import ArticlePicker from './Components/ArticlePicker.js';
import AddToCartButton from '../../../../Components/Store/AddToCartButton.js';
import {
  isActiveSubscription,
  isExpiredSubscription,
  getPlanByType,
  buildInternalArticleUrl
} from '../../../../helpers/subscription.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  productDetails: {
    marginBottom: '5rem',
    marginTop: '-1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem'
    }
  },
  articles: {
    height: 590,
    [theme.breakpoints.down('sm')]: {
      display: ({ showMobileArticlePicker }) => showMobileArticlePicker ? 'block' : 'none',
      margin: '0 auto',
      paddingTop: '0 !important',
      height: 'auto'
    }
  },
  article: {
    [theme.breakpoints.down('sm')]: {
      display: ({ showMobileArticlePicker }) => showMobileArticlePicker ? 'none' : 'block'
    }
  },
  title: {
    marginTop: '2rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 0
    }
  },
  date: {
    marginBottom: '2rem'
  },
  body: {
    marginBottom: '2rem',
    display: 'block'
  },
  hands: {
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: '-1.5rem',
      width: 'calc(100% + 3rem)'
    }
  },
  mobileButton: {
    paddingTop: '30rem',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  alert: {
    marginBottom: 60,
    color: '#ffffff',
    padding: '10px 20px 12px 20px',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function Article () {
  const {
    subscriptionId,
    articlePublicId
  } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [showMobileArticlePicker, setShowMobileArticlePicker] = useState(false);
  const classes = useStyles({ showMobileArticlePicker });

  const { user } = useContext(UserContext);

  // pull in all the necessary data from the backend
  const { data: subscriptionData, loading: subscriptionLoading } = useQuery(subscriptionQueries.SubscriptionById(subscriptionId));
  const { data: articleData } = useQuery(articleQueries.LatestArticleOrByPublicId, {
    variables: {
      id: articlePublicId,
      subscriptionId: parseInt(subscriptionId)
    }
  });
  const { data: availableArticlesData, loading: availableArticlesLoading } = useQuery(articleQueries.ArticlesByUserAndSubscriptionId, {
    variables: {
      subscriptionId: parseInt(subscriptionId)
    }
  });

  const subscription = subscriptionData && subscriptionData.subscriptionById;
  const article = articleData && articleData.latestArticleOrByPublicId;
  let availableArticles = (availableArticlesData && availableArticlesData.articlesByUserAndSubscriptionId) || [];

  // if things are still loading, nothing to render
  if (!subscription || subscriptionLoading || availableArticlesLoading) {
    return <ArticleSkeleton />;
  }

  // has the user ever been subscribed to this subscription?
  let isSubscribed = false;
  let hasSubscribed = false;
  if (user) {
    isSubscribed = isActiveSubscription(user, subscription.id);
    hasSubscribed = isExpiredSubscription(user, subscription.id);
  }

  const showPaidSubscriptionArticles = isSubscribed || hasSubscribed;

  // if the user has never subscribed to this subscription,
  // show the free articles only
  if (subscription && !showPaidSubscriptionArticles) {
    availableArticles = subscription.freeArticles;
  }

  const monthlyPlan = getPlanByType(subscription, 'monthly');

  const handleArticleClick = (chosenArticle) => {
    const url = buildInternalArticleUrl(chosenArticle, subscription);
    setShowMobileArticlePicker(false);
    navigate(url, { replace: true });
  };

  return (
    <>
      <SubscriptionArticleHeader
        subscription={subscription}
        isSubscribed={isSubscribed}
      />

      {
        hasSubscribed && !isSubscribed && (
          <Alert
            className={classes.alert}
            severity='warning'
            variant='filled'
            color='pink'
            action={
              <AddToCartButton
                item={monthlyPlan}
                label='subscribe-again'
                fullWidth
              />
            }
          >
            {t('you-were-previously-subscribed')}
          </Alert>
        )
      }
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        className={clsx(classes.productDetails, showMobileArticlePicker && classes.showMobileArticlePicker)}
        spacing={6}
      >
        <Grid item xs={12} sm={4} className={classes.articles}>
          <ArticlePicker
            availableArticles={availableArticles}
            selectedArticleId={articlePublicId}
            onArticleClick={handleArticleClick}
            allowCalendarView={showPaidSubscriptionArticles}
          />
          <Box className={classes.mobileButton}>
            <StoreButton
              label='back-to-article'
              fullWidth
              onClick={() => {
                setShowMobileArticlePicker(false);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.article}>
          {article && (
            <>
              <Typography variant='h1' className={classes.title}>
                {article.title}
              </Typography>
              <Typography variant='body3' className={classes.date}>
                <LocalisedDisplayDate
                  date={article.publishDateTime}
                />
              </Typography>
              <Box
                sx={{ margin: '3rem 0' }}
                dangerouslySetInnerHTML={
                  { __html: article.body }
                }
              />
              <ArticleHandList article={article} />
              <Box className={classes.mobileButton}>
                <StoreButton
                  label='see-more-articles'
                  fullWidth
                  onClick={() => {
                    setShowMobileArticlePicker(true);
                  }}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
