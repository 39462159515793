// one of more messages for a side of the conversion
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Message from './Message.js';
import MessageTypeSelector from './MessageTypeSelector.js';

const useStyles = makeStyles({
  message: {
    position: 'relative',
    clear: 'both',
    listStyle: 'none',
    margin: 0,
    padding: '1rem 0'
  }
});

export default function MessageGroup ({ messages, position, waitingMessageDelay }) {
  const classes = useStyles();
  return (
    <ul className={classes.message}>
      {
        messages.map((message, index) => {
          return (
            <Message key={index} position={position} waitingMessageDelay={waitingMessageDelay}>
              <MessageTypeSelector message={message} />
            </Message>
          );
        })
      }
    </ul>
  );
}

MessageGroup.propTypes = {
  messages: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired
};
