import React from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button } from '@mui/material';

export default function AdminBackButton ({ destination }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (destination) {
      navigate(destination);
    } else {
      navigate(-1);
    }
  };
  return (
    <Button
      size='small'
      startIcon={<KeyboardBackspaceIcon />}
      variant='outlined'
      onClick={handleClick}
    >
      Back
    </Button>
  );
}
