import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import {
  Typography,
  Box
} from '@mui/material';
import BBTranslate from '../Locale/BBTranslate.js';

const Owned = styled(Box)(({ theme }) => ({
  fontSize: 'smaller',
  display: 'inline-block',
  color: '#fff',
  backgroundColor: theme.palette.styleguide.pink,
  borderRadius: 7,
  padding: 8.5
}));

const Price = ({ product, price, variant }) => {
  const purchased = product &&
    product.purchased &&
    product.type !== 'GiftVoucher';

  return (
    <Typography
      variant={variant || 'h3'}
      color='common.pink'
    >
      {
        purchased
          ? (
            <Owned><BBTranslate textId='owned' /></Owned>
            )
          : (
              product ? product.priceLocalFormatted : price
            )
      }
    </Typography>
  );
};

Price.propTypes = {
  product: PropTypes.object,
  price: PropTypes.string,
  variant: PropTypes.string
};

export default Price;
