import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@mui/material';

const ConfirmModal = (props) => {
  const {
    open,
    title,
    onConfirmClick,
    onCancelClick,
    cancelLabel,
    confirmLabel,
    loading,
    children
  } = props;

  const { t } = useTranslation();

  const theCancelLabel = cancelLabel || 'cancel';
  const theConfirmLabel = confirmLabel || 'confirm';

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelClick}
          color='primary'
        >
          {t(theCancelLabel)}
        </Button>
        <Button
          onClick={onConfirmClick}
          color='primary'
          disabled={loading}
        >
          {
          loading
            ? <CircularProgress size={24} />
            : t(theConfirmLabel)
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
