import React from 'react';
import {
  Skeleton,
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '8rem',
    marginTop: '-1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem'
    }
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      // margin: '0 auto'
    }
  },
  line: {
    height: 40,
    marginBottom: 20
  },
  tags: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));

export default function SubscriptionSkeleton () {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={{ xs: 1, sm: 1 }}
        className={classes.header}
      >
        <Grid item sm={3} className={classes.image}>
          <Skeleton variant='circular' sx={{ height: 200, width: 200 }} />
        </Grid>
        <Grid item sm={7}>
          <div className={classes.tags}>
            <Skeleton variant='rectangle' className={classes.line} />
            <Skeleton variant='rectangle' className={classes.line} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={{ xs: 1, sm: 3 }}
      >
        <Grid item sm={8}>
          <Skeleton variant='rectangle' className={classes.line} />
          <Skeleton variant='rectangle' className={classes.line} />
          <Skeleton variant='rectangle' className={classes.line} />
          <Skeleton variant='rectangle' className={classes.line} />
          <Skeleton variant='rectangle' className={classes.line} />
          <Skeleton variant='rectangle' className={classes.line} />
        </Grid>
        <Grid item sm={4}>
          <Skeleton variant='rectangle' sx={{ height: 400 }} />
        </Grid>
      </Grid>
    </>
  );
}
