import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  authorQueries,
  priceQueries,
  tagQueries
} from '../../../graphql/queries';
import SubscriptionForm from './SubscriptionForm.js';

const AddSubscription = () => {
  const subscription = {
    articles: [],
    author: {},
    subscriptionPlans: [
      { type: 'Yearly', price: 0 },
      { type: 'Monthly', price: 0 }
    ],
    tags: [],
    tagsCache: [],
    images: {}
  };

  const [authors, setAuthors] = useState();
  const [prices, setPrices] = useState();
  const [tags, setTags] = useState();

  const [getAuthors] = useLazyQuery(authorQueries.Authors, {
    onCompleted: (data) => {
      if (data && data.authors) {
        setAuthors(data.authors);
      }
    },
    onError: (err) => {
      console.log('getAuthors err', err);
    }
  });

  const [getPrices] = useLazyQuery(priceQueries.Prices, {
    onCompleted: (data) => {
      if (data && data.prices) {
        setPrices(data.prices);
      }
    },
    onError: (err) => {
      console.log('getPrices err', err);
    }
  });

  const [getTags] = useLazyQuery(tagQueries.Tags, {
    onCompleted: (data) => {
      if (data && data.tags) {
        setTags(data.tags);
      }
    },
    onError: (err) => {
      console.log('getTags err', err);
    }
  });

  useEffect(() => {
    getAuthors();
    getPrices();
    getTags();
  }, [getAuthors, getPrices, getTags]);

  // navigating back to the previous page
  const navigate = useNavigate();

  const handleSave = (upsertedSubscripton) => {
    navigate(`/admin/subscriptions/${upsertedSubscripton.id}/${upsertedSubscripton.title}`);
  };

  if (!authors || !prices) {
    return null;
  }

  return (
    <SubscriptionForm
      subscription={subscription}
      authors={authors}
      prices={prices}
      tags={tags}
      onSave={handleSave}
    />
  );
};

export default AddSubscription;
