import React from 'react';
import {
  Avatar,
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import SuitPattern from '../SuitPattern.js';

const HeaderBox = styled(Box)(({ theme, colour }) => ({
  backgroundColor: colour,
  ...theme.extendableBackground,
  display: 'flex',
  // move up the header and allow it to appear behind the breadcrumbs
  top: -91,
  paddingTop: 91,
  zIndex: -1,
  // shorten it, allowing for the author avatar to stick out
  height: 247,
  overflow: 'visible',
  marginBottom: '4rem',
  [theme.breakpoints.down('md')]: {
    // slightly hacky way of doing all of the above _and_ pulling the content up a little
    marginBottom: '-61px',
    height: 'auto',
    paddingBottom: '2rem'
  }
}));

const AuthorAvater = styled(Avatar)(({ theme }) => ({
  width: 219,
  height: 219,
  marginBottom: '2.5rem',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: 124,
    height: 124
  }
}));

const AuthorName = styled(Typography)(({ theme }) => ({
  fontSize: '3.8rem',
  fontWeight: 600,
  color: '#fff',
  marginTop: 0,
  marginLeft: '4rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '2.4rem',
    marginLeft: '2.5rem'
  }
}));

const ChildrenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '4rem',
  marginTop: '1rem',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'flex-start',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    alignItems: 'start',
    flexDirection: 'column',
    marginLeft: '2.5rem'
  }
}));

const PatternBox = styled(Box)(({ theme }) => ({
  height: '100%',
  width: 500,
  position: 'absolute',
  overflowY: 'hidden',
  top: 0,
  right: -50,
  [theme.breakpoints.down('md')]: {
    right: -300
  }
}));

export default function AuthorHeader ({ title, author, colour, children }) {
  return (
    <HeaderBox colour={colour}>
      <AuthorAvater
        alt={author.fullName}
        src={author.avatarPath}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', zIndex: 2 }}>
        <AuthorName variant='h1'>
          {title}
        </AuthorName>
        <ChildrenBox>
          {
            children
          }
        </ChildrenBox>
      </Box>
      <PatternBox>
        <SuitPattern
          opacity={0.1}
        />
      </PatternBox>
    </HeaderBox>
  );
}
