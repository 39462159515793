import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import AuthorForm from './AuthorForm.js';
import AuthorById from '../../../Components/Queries/AuthorById.js';
import EnumValues from '../../../Components/Queries/EnumValues.js';

const AuthorDetails = (props) => {
  const { id, ...other } = props;
  return (
    <>
      <AuthorById id={parseInt(id)}>
        {data => (
          <EnumValues name='LanguageEnum'>
            {languageEnum => (
              <Mutation
                mutation={mutations.UpsertAuthor}
              >
                {upsertAuthor => (
                  <>
                    <AuthorForm
                      upsertAuthor={upsertAuthor}
                      author={data}
                      languageEnum={languageEnum}
                      {...other}
                    />
                  </>
                )}
              </Mutation>
            )}
          </EnumValues>
        )}
      </AuthorById>
    </>
  );
};

AuthorDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default AuthorDetails;
