import React, { useContext } from 'react';
import { CartContext, DrawerContext } from '../../Context';
import StoreButton from '../Forms/Store/StoreButton.js';

export default function AddToCartButton ({ item, purchased, fullWidth, label }) {
  const {
    addProductToCart,
    addSubscriptionToCart,
    removeFromCart,
    checkIfInCart
  } = useContext(CartContext);

  const {
    openDrawer
  } = useContext(DrawerContext);

  const handleCartClick = () => {
    if (item.subscription) {
      addSubscriptionToCart(item);
    } else {
      addProductToCart(item);
    }
    openDrawer('cart');
  };

  const handleRemoveFromCartClick = () => {
    removeFromCart(item.id);
  };

  const isInCart = checkIfInCart(item.id);
  return (
    !purchased && (
      isInCart
        ? (<StoreButton label='remove-from-cart' onClick={handleRemoveFromCartClick} color='warning' fullWidth={fullWidth} />)
        : (<StoreButton label={label || 'add-to-cart'} onClick={handleCartClick} fullWidth={fullWidth} />)
    )
  );
}
