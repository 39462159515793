import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Grid
} from '@mui/material';
import { articleQueries } from '../../../graphql/queries';
import { ArticleForm } from './ArticleForm.js';
import Hands from '../Content/Hands/Hands.js';

const EditArticle = () => {
  const { articleId } = useParams();
  const { data } = useQuery(articleQueries.ArticleById(articleId));

  if (!data) {
    return null;
  }

  const article = { ...data.articleById, subscriptionId: data.articleById.subscription.id };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid xs={6} item>
        <ArticleForm article={article} />
      </Grid>
      <Grid xs={6} item style={{ marginTop: -38 }}>
        <Hands parent={article} parentType='article' subscription={article.subscription} />
      </Grid>
    </Grid>
  );
};

export default EditArticle;
