import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Grid,
  Link
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import DeleteButton from '../../../Components/Forms/DeleteButton.js';
import { makeStyles } from '@mui/styles';
import { tagQueries } from '../../../graphql/queries';
import BBInput from '../../../Components/Forms/BBInput.js';
import BBSelect from '../../../Components/Forms/BBSelect.js';
import BBSubmit from '../../../Components/Forms/BBSubmit.js';
import TagManager from '../../../Components/TagManager.js';
import ImageUploader from '../../../Components/ImageUploader.js';
import RichText from '../../../Components/Forms/RichText/RichText.js';
import { parseError } from '../../../helpers/error.js';
import { stripTypeNameFromMutationVars } from '../../../helpers/gql.js';
import { formatS3FilePath } from '../../../helpers/file.js';
import { convertReactSelectTags, getTagsByGroup } from '../../../helpers/tag.js';
import { capitalize } from '../../../helpers/global.js';
import ProductLinks from './ProductLinks.js';
import ContentShare from './ContentShare.js';
import { UserContext } from '../../../Context/UserContext.js';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  }
}));

const ProductForm = (props) => {
  const {
    product,
    authors,
    tags,
    languageEnum,
    prices,
    showCheckboxes,
    deleteProduct,
    type,
    onFormSubmitted,
    duplicateProduct,
    upsertProduct,
    openSnackbarYouGibbon
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [fields, setFields] = useState(product);
  const [imagePath, setImagePath] = useState(product.images ? product.images.cover : '');
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [linksModalOpen, setLinksModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const handleCheckboxChange = name => event => {
    const { onSale } = fields;
    if ((name === 'onSale' || name === 'productLinksEnabled') && event.target.checked && imagePath === '') {
      openSnackbarYouGibbon('Image must be set to put product on sale or enable links', 'error');
    } else if (name === 'promoted' && event.target.checked && !onSale) {
      openSnackbarYouGibbon('Product must be on sale before you can promote it', 'error');
    } else {
      handleInputChange(
        {
          name,
          value: event.target.checked,
          error: null
        }
      );
    }
  };

  const handleUploadComplete = (fileName, folder) => {
    handleInputChange({
      name: 'imagePath',
      value: formatS3FilePath(fileName, folder)
    });
  };

  const handleUploadError = (message) => {
    openSnackbarYouGibbon(message, 'error');
  };

  const handleInputChange = (input) => {
    const newFields = { ...fields };
    const newFieldErrors = { ...fieldErrors };
    if (input.name === 'imagePath') {
      setImagePath(input.value);
    } else {
      if (input.name === 'author') {
        newFields.author = {
          id: input.value
        };
        newFields.authorId = input.value;
      } else if (input.name === 'price') {
        newFields.price = {
          id: input.value
        };
        newFields.priceId = input.value;
      } else {
        newFields[input.name] = input.value;
      }
      newFieldErrors[input.name] = input.error;

      // if onSale unchecked then uncheck promoted
      if (input.name === 'onSale' && !input.value) {
        newFields.promoted = false;
      }
      setFields(newFields);
      setFieldErrors(newFieldErrors);
    }
  };

  const handleDeleteClick = () => {
    if (deleteProduct) {
      deleteProduct(
        {
          variables: { id: fields.id }
        }
      ).then((response) => {
        openSnackbarYouGibbon('Product deleted', 'success');
        setTimeout(() => {
          return navigate('/admin/products');
        }, 500);
      }).catch(err => {
        const parsedError = parseError(err);
        openSnackbarYouGibbon(parsedError.message, 'error');
      });
    }
  };

  const handleDuplicateClick = () => {
    if (duplicateProduct) {
      duplicateProduct(
        {
          variables: { id: fields.id }
        }
      ).then((response) => {
        openSnackbarYouGibbon('Product duplicated', 'success');
        setTimeout(() => {
          return navigate(`/admin/products/set/${response.data.duplicateProduct.id}/duplicate`);
        }, 500);
      }).catch(err => {
        const parsedError = parseError(err);
        openSnackbarYouGibbon(parsedError.message, 'error');
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFields = { ...fields };
    // capitalize type
    newFields.type = capitalize(type);
    // add images prop
    if (product.images) {
      newFields.images = product.images;
      newFields.images.cover = imagePath;
    } else {
      newFields.images = { cover: imagePath };
    }
    if (validate()) {
      setIsLoading(true);
      upsertProduct(
        {
          variables: stripTypeNameFromMutationVars(newFields),
          refetchQueries: [
            {
              query: tagQueries.Tags
            }
          ]
        }
      ).then((response) => {
        openSnackbarYouGibbon('Product successfully saved', 'success');
        if (onFormSubmitted) {
          setTimeout(() => {
            return onFormSubmitted(
              response.data.upsertProduct.type,
              response.data.upsertProduct.id,
              response.data.upsertProduct.title
            );
          }, 500);
        }
      }).catch(err => {
        const parsedError = parseError(err);
        openSnackbarYouGibbon('Error submitting product: ' + parsedError.message, 'error');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const handleTagUpdate = (updatedTags) => {
    // convert the react-select tags to bb tags
    updatedTags = convertReactSelectTags(updatedTags);

    // get the group tags only as we need to be able to append the new list to this
    const groupTagsOnly = _.remove(fields.tags, function (tag) {
      return tag.tagGroup !== null;
    });

    // join the group tags with the new list of tags from react-select
    updatedTags = _.concat(groupTagsOnly, updatedTags);

    setFields({ ...fields, tags: updatedTags });
  };

  const getGroupedTag = (group) => {
    const tag = _.find(fields.tags, (tag) => {
      if (tag && tag.tagGroup) {
        return tag.tagGroup.name === group;
      }
      return null;
    });
    if (tag) {
      return tag.name;
    }
    return '';
  };

  const handleGroupTagChanged = (input) => {
    // update the current value for the group
    const allTags = tags;
    const newTags = [...fields.tags];
    const group = input.name;
    const tag = _.find(newTags, (tag) => {
      if (tag.tagGroup) {
        return tag.tagGroup.name === group;
      }
      return null;
    });

    const groupTags = getTagsByGroup(allTags, group, fields.language);

    // check to see if the group tag is already added
    // if not add it the tags list, if it is replace it with new one
    if (tag) {
      const index = _.findIndex(newTags, { id: tag.id });
      const newTag = _.find(groupTags, { name: input.value });
      if (newTag !== undefined) {
        newTags.splice(index, 1, newTag);
      } else {
        // remove this groups tag as its undefined (as in the case when '-' is selected)
        newTags.splice(index, 1);
      }
    } else {
      const groupTag = _.find(groupTags, (tag) => {
        return tag.name === input.value;
      });
      newTags.push(groupTag);
    }
    setFields({ ...fields, tags: newTags });
  };

  const handleManageLinksClick = () => {
    setLinksModalOpen(true);
  };

  const handleLinksModalClose = () => {
    setLinksModalOpen(false);
  };

  const handleShareClick = () => {
    setShareModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const validate = () => {
    const requiredFields = [
      { name: 'title', type: 'text' },
      { name: 'description', type: 'text' },
      { name: 'priceId', type: 'list' }
    ];
    if (type === 'set') {
      requiredFields.push(
        { name: 'authorId', type: 'list' },
        { name: 'system', type: 'tag' },
        { name: 'difficulty', type: 'tag' },
        { name: 'type', type: 'tag' },
        { name: 'language', type: 'text' }
      );
    }
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
    const blankFields = requiredFields.filter((field) => {
      if (field.type === 'tag') {
        // get tag for field
        const tag = _.find(fields.tags, (t) => {
          return t.tagGroup && field.name === t.tagGroup.name.toLowerCase();
        });
        return tag === undefined;
      } else {
        return fields[field.name] === '' || fields[field.name] === null;
      }
    });
    if (errMessages.length || blankFields.length) return false;
    return true;
  };

  const isAdmin = user.type.toLowerCase() === 'admin';

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl variant='standard' margin='normal' required fullWidth>
            <BBInput
              id='title'
              name='title'
              type='text'
              label='Title'
              autoFocus
              required
              maxLength={100}
              value={fields.title}
              onChange={handleInputChange}
              validate={(val) => (val ? '' : 'Title is required')}
            />
          </FormControl>

          <FormControl variant='standard' margin='normal' required fullWidth>
            <RichText
              name='description'
              value={fields.description}
              label='Description *'
              height={400}
              onChange={handleInputChange}
              hideCount
            />
          </FormControl>

          <BBSelect
            id='author'
            name='author'
            label='Author'
            required
            onChange={handleInputChange}
            value={
              fields.author
                ? fields.author.id
                : null
            }
            valueField='id'
            keyField='fullName'
            items={authors}
            validate={(val) => (val ? '' : 'Author is required')}
          />

          <BBSelect
            id='price'
            name='price'
            label='Price (USD)'
            required
            onChange={handleInputChange}
            value={
              fields.priceId
                ? fields.priceId
                : null
            }
            valueField='id'
            keyField='priceUSD'
            items={prices}
            validate={(val) => (val ? '' : 'Price is required')}
          />

          <BBSelect
            id='language'
            name='language'
            label='Language'
            required
            onChange={handleInputChange}
            value={fields.language}
            valueField='name'
            keyField='name'
            items={languageEnum}
            validate={(val) => (val ? '' : 'Language is required')}
          />

          {
            type === 'set'
              ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <BBSelect
                        id='system'
                        name='System'
                        label='System'
                        required
                        onChange={handleGroupTagChanged}
                        value={getGroupedTag('System')}
                        valueField='name'
                        keyField='name'
                        items={getTagsByGroup(tags, 'System', fields.language)}
                        validate={(val) => (val ? '' : 'System is required')}
                      />
                    </Grid>
                    <Grid item xs>
                      <BBSelect
                        id='difficulty'
                        name='Difficulty'
                        label='Difficulty'
                        required
                        onChange={handleGroupTagChanged}
                        value={getGroupedTag('Difficulty')}
                        valueField='name'
                        keyField='name'
                        items={getTagsByGroup(tags, 'Difficulty', fields.language)}
                        validate={(val) => (val ? '' : 'Difficulty is required')}
                      />
                    </Grid>
                    <Grid item xs>
                      <BBSelect
                        id='type'
                        name='Type'
                        label='Type'
                        required
                        onChange={handleGroupTagChanged}
                        value={getGroupedTag('Type')}
                        valueField='name'
                        keyField='name'
                        items={getTagsByGroup(tags, 'Type', fields.language)}
                        validate={(val) => (val ? '' : 'Type is required')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    &nbsp;
                  </Grid>
                </>
                )
              : null
          }
          {
            isAdmin && showCheckboxes
              ? (
                <Grid
                  container spacing={3}
                  justifyContent='center'
                  alignItems='flex-end'
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fields.onSale}
                          onChange={handleCheckboxChange('onSale')}
                          value='onSale'
                        />
                      }
                      label='On sale'
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fields.promoted}
                          onChange={handleCheckboxChange('promoted')}
                          value='promoted'
                          color='primary'
                        />
                      }
                      label='Promoted'
                    />
                  </Grid>
                </Grid>
                )
              : null
          }
          {
            fields.id
              ? (
                <FormControl margin='normal' fullWidth>
                  <Grid
                    container spacing={3}
                    justifyContent='center'
                    alignItems='flex-end'
                  >
                    <Grid item xs>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fields.productLinksEnabled}
                            onChange={handleCheckboxChange('productLinksEnabled')}
                            value='productLinksEnabled'
                          />
                        }
                        label='Product links enabled'
                      />
                      <Link
                        onClick={handleManageLinksClick}
                        text='Manage links'
                      >
                        (Manage links)
                      </Link>
                    </Grid>
                  </Grid>
                </FormControl>
                )
              : null
          }
          <FormControl margin='normal' fullWidth style={{ marginBottom: 20, marginTop: 30 }}>
            <TagManager
              options={getTagsByGroup(tags)}
              selected={fields.tags}
              onUpdate={handleTagUpdate}
              createable={user.type === 'Admin'}
            />
          </FormControl>

          <ImageUploader
            key='artwork'
            id='artwork'
            uploadFolder='artwork'
            currentImageSrc={imagePath}
            onUploadComplete={handleUploadComplete}
            onUploadError={handleUploadError}
          />

          <div style={{ textAlign: 'right' }}>
            {
              fields.id && fields.productLinksEnabled
                ? (
                  <FormControl margin='normal' style={{ marginRight: 10 }}>
                    <Button
                      variant='outlined'
                      startIcon={<ShareIcon />}
                      onClick={handleShareClick}
                    >
                      Share
                    </Button>
                  </FormControl>
                  )
                : null
            }
            {
              isAdmin && type === 'set' && fields.id
                ? (
                  <FormControl margin='normal' style={{ marginRight: 10 }}>
                    <Button
                      variant='outlined'
                      onClick={handleDuplicateClick}
                    >
                      Duplicate
                    </Button>
                  </FormControl>
                  )
                : null
            }
            {
              deleteProduct
                ? (
                  <FormControl variant='standard' margin='normal' style={{ marginRight: 10 }}>
                    <DeleteButton
                      onDeleteOkClick={handleDeleteClick}
                    />
                  </FormControl>
                  )
                : null
            }
            <FormControl margin='normal'>
              <BBSubmit
                label='Save'
                disabled={isLoading || !validate()}
                isLoading={isLoading}
                className={classes.submit}
              />
            </FormControl>
          </div>
        </form>
      </Paper>
      {
        fields.id &&
          <ProductLinks
            open={linksModalOpen}
            onModalClose={handleLinksModalClose}
            productId={fields.id}
            productLanguage={fields.language}
          />
      }
      <ContentShare
        product={product}
        open={shareModalOpen}
        onModalClose={handleShareModalClose}
      />
    </main>
  );
};

ProductForm.propTypes = {
  classes: PropTypes.object.isRequired,
  upsertProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func,
  duplicateProduct: PropTypes.func,
  onFormSubmitted: PropTypes.func,
  product: PropTypes.object.isRequired,
  authors: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  languageEnum: PropTypes.array.isRequired,
  prices: PropTypes.array.isRequired,
  openSnackbarYouGibbon: PropTypes.func.isRequired,
  showCheckboxes: PropTypes.bool,
  type: PropTypes.string.isRequired
};

export default ProductForm;
