import React, { useContext } from 'react';
import { GiftContext, DrawerContext } from '../../Context';
import StoreButton from '../Forms/Store/StoreButton';

export default function SendAsGiftButton ({ item, fullWidth }) {
  const { openDrawer } = useContext(DrawerContext);
  const { initSubscriptionGift } = useContext(GiftContext);

  const handleGiftClick = () => {
    initSubscriptionGift(item);
    openDrawer('gift');
  };

  return (
    <StoreButton label='send-as-gift' onClick={handleGiftClick} color='secondary' fullWidth={fullWidth} />
  );
}
