import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { dynamoProductQueries } from '../../graphql/queries';
import { markPurchasedProducts } from '../../helpers/purchase.js';
import { UserContext } from '../../Context';

const DynamoProductSearch = ({ children, options }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const { user } = useContext(UserContext);

  const variables = {
    orderBy: options.orderBy,
    orderByDirection: options.orderByDirection,
    locale: {
      language: options.language,
      currency: options.currency
    },
    tags: options.tags,
    pagination: options.pagination
  };

  const { data, fetchMore, loading } = useQuery(dynamoProductQueries.DynamoProductSearch, {
    variables
  });

  const loadMore = async () => {
    setLoadingMore(true);
    await fetchMore({
      query: dynamoProductQueries.DynamoProductSearch,
      variables: {
        ...variables,
        pagination: {
          pageSize: variables.pagination.pageSize,
          paginationKey: data.dynamoProductSearch.paginationKey
        }
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const prevProducts = previousResult.dynamoProductSearch.products;
        const newProducts = fetchMoreResult.dynamoProductSearch.products;

        return {
          ...previousResult,
          dynamoProductSearch: {
            ...previousResult.dynamoProductSearch,
            products: [...prevProducts, ...newProducts],
            paginationKey: fetchMoreResult.dynamoProductSearch.paginationKey
          }
        };
      }
    });
    setLoadingMore(false);
  };

  // mark products that have been purchased as 'Purchased'
  let markedProducts;
  if (
    data &&
    data.dynamoProductSearch
  ) {
    markedProducts = markPurchasedProducts(data.dynamoProductSearch.products, user);
  }

  return children({
    products: markedProducts,
    loadMore: data && data.dynamoProductSearch.paginationKey ? loadMore : null,
    loading,
    loadingMore
  });
};

DynamoProductSearch.propTypes = {
  children: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default DynamoProductSearch;
