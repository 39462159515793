// there a few places where we need to get the parent/product (collection, set, article)
// and the hand at the same time so good to have this a reusable hook
import { useQuery } from '@apollo/client';
import {
  handQueries
} from '../graphql/queries';
import { convertLangCodeToGraphqlEnum } from '../helpers/locale.js';

export default function useHandAndParentQuery ({ handId, parentId, language, parentQuery }) {
  const { data: handData } = useQuery(handQueries.HandById, {
    variables: {
      id: handId
    }
  });
  const { data: parentData } = useQuery(parentQuery, {
    variables: {
      id: parentId,
      language: convertLangCodeToGraphqlEnum(language)
    }
  });

  if (!handData || !parentData) {
    return null;
  }

  return {
    handData,
    parentData
  };
}
