import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  Paper,
  Link,
  Box,
  Container
} from '@mui/material';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import CheckoutAppBar from './Components/CheckoutAppBar.js';
import MainContainer from '../../../../Components/Layout/MainContainer.js';
import CartItem from '../../../../Components/Store/Cart/CartItem.js';
import GreyLine from '../../../../Components/Store/GreyLine.js';
import RedeemVoucher from './Components/RedeemVoucher.js';
import Price from '../../../../Components/Store/Price.js';
import RemoveButton from '../../../../Components/Store/RemoveButton.js';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';
import Section from './Components/Section.js';
import LeftColumnLogin from './Components/LeftColumnLogin.js';
import RegisterForm from '../../Register/RegisterForm.js';
import NewCardForm from '../../../../Components/Store/Checkout/NewCardForm.js';
import SuccessModal from './Components/SuccessModal.js';
import IdealPaymentForm from './Components/IdealPaymentForm.js';
import NiceError from '../../../../Components/Errors/NiceError.js';
import AcceptedCards from '../../../../Components/Store/AcceptedCards.js';
import SavingBackdrop from '../../../../Components/Forms/SavingBackdrop.js';

const useStyles = makeStyles((theme) => {
  return ({
    iconMenu: {
      color: theme.palette.styleguide.maroon
    },
    backBar: {
      background: '#fff',
      height: '5rem',
      borderBottom: '1px solid #E6E6E2'
    },
    mainHeading: {
      margin: '3rem 0'
    },
    back: {
      height: '5rem',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      padding: 5
    },
    backLink: {
      height: '60%',
      color: theme.palette.styleguide.maroon,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    backWord: {
      position: 'relative',
      marginLeft: '.5rem',
      top: -5
    },
    column: {
      width: '100%',
      maxWidth: 450,
      flexShrink: 1,
      alignItems: 'start'
    },
    paper: {
      border: '1px solid #BFBFBF',
      borderRadius: 0,
      padding: '2rem'
    },
    sectionHeader: {
    },
    sectionContent: {
      background: '#FAFAFA'
    },
    miniCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      '& img': {
        width: 50
      }
    },
    errorContainer: {
      maxWidth: 952
    },
    newCardHeading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });
});

const Checkout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isValid,
    submitLoading,
    redeemLoading,
    success,
    error,
    cart,
    user,
    locale,
    total,
    isFreeAfterDiscount,
    paymentMethods,
    selectedPaymentMethodId,
    selectedBank,
    remainingBalance,
    savePaymentMethod,
    successText,
    onBackClick,
    onLoginClick,
    onVoucherApplyClick,
    onRemoveVoucherClick,
    onSubmitClick,
    onPaymentMethodChange,
    onSavePaymentMethodChange,
    onRegisterFormComplete,
    onCardFormComplete,
    onBankSelected,
    onSuccessComplete
  } = props;

  return (
    <>
      <CheckoutAppBar />
      <div className={classes.backBar}>
        <MainContainer>
          <div className={classes.back}>
            <Link onClick={onBackClick} className={classes.backLink}>
              <BackIcon /><span className={classes.backWord}>{t('back')}</span>
            </Link>
          </div>
        </MainContainer>
      </div>
      <MainContainer>
        <Typography variant='h1' className={classes.mainHeading}>{t('payment')}</Typography>
        <Container className={classes.errorContainer}>
          <NiceError err={error} defaultText='completing-your-purchase' />
        </Container>
        <Grid
          container
          sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' } }}
          justifyContent='center'
          alignItems='flex-start'
          spacing={5}
          wrap='nowrap'
        >
          {/* LEFT COLUMN */}
          <Grid item className={classes.column}>
            <Section
              id='login'
              header={<LeftColumnLogin user={user} onLoginClick={onLoginClick} />}
              body={
                !user &&
                  <RegisterForm
                    onComplete={onRegisterFormComplete}
                  />
              }
            />
            {
              isFreeAfterDiscount &&
                (
                  <Section
                    id='none'
                    selected={selectedPaymentMethodId === 'none'}
                    onSectionSelected={onPaymentMethodChange}
                    header={
                      <Box className={classes.newCardHeading}>
                        {t('no-payment-method-necessary')}
                      </Box>
                    }
                  />
                )
            }
            {
              locale.currency === 'EUR' && // locale.language === 'nl-nl' &&
                <Section
                  id='ideal'
                  selected={selectedPaymentMethodId === 'ideal'}
                  onSectionSelected={onPaymentMethodChange}
                  disabled={isFreeAfterDiscount}
                  header={
                    <Box className={classes.miniCard}>
                      <span>iDEAL {t('netherlands-only')}</span>
                      <img src='/img/components/card_details/icon_ideal.png' alt='ideal' />
                    </Box>
                  }
                  body={
                    <IdealPaymentForm
                      onBankSelected={onBankSelected}
                      selectedBank={selectedBank}
                    />
                  }
                />
            }
            {
              paymentMethods && paymentMethods.map(pm => (
                <Section
                  key={pm.id}
                  id={pm.id}
                  disabled={isFreeAfterDiscount}
                  selected={selectedPaymentMethodId === pm.id}
                  onSectionSelected={onPaymentMethodChange}
                  header={
                    <>
                      <Box className={classes.miniCard}>
                        <span>{t('use-card-number', { number: `xxxx xxxx xxxx ${pm.last4}` })}</span>
                        <img src={`/img/components/card_details/icon_${pm.cardType}.png`} alt={pm.cardType} />
                      </Box>
                    </>
                  }
                />
              ))
            }
            <Section
              id='new'
              disabled={isFreeAfterDiscount}
              selected={selectedPaymentMethodId === 'new'}
              onSectionSelected={onPaymentMethodChange}
              header={
                <Box className={classes.newCardHeading}>
                  <span>
                    {
                      user && paymentMethods && paymentMethods.length > 0
                        ? t('use-a-different-card')
                        : t('pay-by-card')
                    }
                  </span>
                  <AcceptedCards />
                </Box>
              }
              body={
                <NewCardForm
                  onFormComplete={onCardFormComplete}
                  onSavePaymentMethodChange={onSavePaymentMethodChange}
                  savePaymentMethod={savePaymentMethod}
                  cart={cart}
                />
              }
            />
            {
              !isFreeAfterDiscount && (
                <Box sx={{ margin: '3rem 0 1rem' }}>
                  <Typography variant='body1'>
                    {t('you-may-be-subject-to-sales-tax')}
                  </Typography>
                </Box>
              )
            }

            <Box sx={{ marginTop: '3rem' }}>
              <StoreButton
                disabled={!isValid || submitLoading}
                onClick={onSubmitClick}
                label={`${t('complete-purchase')} - ${total}`}
                loading={submitLoading}
                fullWidth
              />
            </Box>
          </Grid>
          {/* END LEFT COLUMN */}

          {/* RIGHT COLUMN */}
          <Grid item className={classes.column}>
            <Paper className={classes.paper} sx={{ marginBottom: 2 }}>
              {
                cart && cart.voucher
                  ? (
                    <div style={{ display: 'flex' }}>
                      <Typography variant='body1'>
                        {t('voucher-code-applied', { code: cart.voucher.code })}
                        {
                          remainingBalance &&
                            ' ' + t('voucher-balance-remaining', { balance: remainingBalance })
                        }
                      </Typography>
                      <div>
                        <RemoveButton
                          onClick={onRemoveVoucherClick}
                        />
                      </div>
                    </div>
                    )
                  : <RedeemVoucher
                      onVoucherApplyClick={onVoucherApplyClick}
                      isLoading={redeemLoading}
                    />
              }
            </Paper>
            <Paper className={classes.paper}>
              {
                cart && cart.items && cart.items.map((item, index) => (
                  <span key={index}>
                    <CartItem
                      item={item}
                      currency={locale.currency}
                    />
                    <GreyLine />
                  </span>
                ))
              }
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <Typography variant='body1'>{t('total')}</Typography>
                <Price variant='h4' price={total} />
              </div>
            </Paper>
          </Grid>
          {/* END RIGHT COLUMN */}

        </Grid>
      </MainContainer>
      <SuccessModal open={success} onSuccess={onSuccessComplete} text={successText} />
      {
        user && !paymentMethods && (<SavingBackdrop open />)
      }
    </>
  );
};

export default Checkout;
