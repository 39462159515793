import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';
import StoreTable from '../../../../Components/Store/StoreTable';
import LocalisedDisplayDate from '../../../../Components/Locale/LocalisedDisplayDate';
import { useTranslation } from 'react-i18next';

const Actions = (props) => {
  const {
    userSubscriptionPlan,
    onCancelSubscriptionClick,
    onChangeSubscriptionClick,
    onRestoreSubscriptionClick
  } = props;

  const handleCancelClick = () => {
    onCancelSubscriptionClick(userSubscriptionPlan.id);
  };

  const handleChangeClick = () => {
    onChangeSubscriptionClick(userSubscriptionPlan.id);
  };

  const handleRestoreClick = () => {
    onRestoreSubscriptionClick(userSubscriptionPlan.id);
  };

  return (
    <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
      {userSubscriptionPlan.endDateTime
        ? (<StoreButton onClick={handleRestoreClick} label='restore' />)
        : (
          <>
            <div>
              <StoreButton onClick={handleCancelClick} label='cancel' />
            </div>
            {
              userSubscriptionPlan.subscriptionPlan.type === 'Monthly' && (
                <Box sx={{
                  marginLeft: { sm: 1 },
                  marginTop: { xs: 1, sm: 0 }
                }}
                >
                  <StoreButton
                    onClick={handleChangeClick}
                    label='change-to-yearly'
                  />
                </Box>
              )
            }
          </>
          )}
    </Box>
  );
};

export default function SubscriptionTable ({
  expired,
  userSubscriptionPlans,
  onCancelSubscriptionClick,
  onChangeSubscriptionClick,
  onRestoreSubscriptionClick
}) {
  const { t } = useTranslation();

  const aSubscriptionIsCancelling = userSubscriptionPlans.some(
    usp => usp.endDateTime && moment(usp.endDateTime) > Date.now()
  );

  const rows = userSubscriptionPlans.map((usp, index) => {
    const row = [
      usp.subscriptionPlan.subscription.title,
      usp.subscriptionPlan.type,
      <LocalisedDisplayDate key={index} date={usp.startDateTime} />,
      !expired && (
        <Actions
          key={index}
          userSubscriptionPlan={usp}
          onCancelSubscriptionClick={onCancelSubscriptionClick}
          onChangeSubscriptionClick={onChangeSubscriptionClick}
          onRestoreSubscriptionClick={onRestoreSubscriptionClick}
        />
      )
    ];

    if (aSubscriptionIsCancelling || expired) {
      row.splice(3, 0, usp.endDateTime
        ? (<LocalisedDisplayDate date={usp.endDateTime} />)
        : null);
    }

    return row;
  });

  const tableData = {
    columns: [
      t('product'),
      t('plan'),
      t('start-date'),
      t('actions')
    ],
    rows
  };

  if (aSubscriptionIsCancelling) {
    tableData.columns.splice(3, 0, t('cancelling-on'));
  }

  if (expired) {
    tableData.columns.splice(3, 0, t('end-date'));
    tableData.columns.splice(4);
  }

  return (
    <StoreTable tableData={tableData} />
  );
}
