import React from 'react';
import { GlobalStyles } from '@mui/material';
import { ReactTitle } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../helpers/auth.js';
import Drawer from '../../Components/Drawer/Drawer.js';
import AppBar from '../../Components/Layout/AppBar/AppBar.js';
import Footer from '../../Components/Layout/Footer.js';
import Hero from './Hero.js';
import FeaturedSubscriptions from './FeaturedSubscriptions.js';
import Highlight1 from './Highlight1.js';
import Highlight2 from './Highlight2.js';
import WhySubscribe from './WhySubscribe.js';
import SittingManCta from '../../Components/Sales/SittingManCta.js';
import Faq from './FAQ.js';

const globalStyles = {
  html: {
    fontSize: 10,
    lineHeight: 10,
    '@media all and (min-width: 1680px)': {
      fontSize: 11
    }
  },
  body: {
    overflowX: 'hidden'
  }
};

const Subscriptions = () => {
  const { t } = useTranslation();
  const loggedIn = isAuthenticated();
  const navigate = useNavigate();

  // TODO do we want something with more zazz?
  const translatedTitle = t('subscriptions');

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <ReactTitle title={`BridgeBee - ${translatedTitle}`} />
      <Drawer />
      <AppBar
        loggedIn={loggedIn}
        transparent
      />
      <main className='container'>
        <Hero />
        <FeaturedSubscriptions />
        <Highlight1 />
        <WhySubscribe />
        <Highlight2 />
        <Faq />
        <SittingManCta
          heading={t('subscribe-today')}
          body={t('give-it-a-try-youll-love-it')}
          cta={t('see-subscription-options')}
          onCtaClick={() => { navigate('/products/all?Purchase+type=Subscription'); }}
        />
      </main>
      <Footer loggedIn={loggedIn} />
    </>
  );
};

export default Subscriptions;
