import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Truncate from 'react-text-truncate';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import { buildInternalArticleUrl } from '../../../../../helpers/subscription.js';

const Article = styled(Paper)({
  height: 216,
  backgroundColor: '#fff',
  padding: 20,
  borderRadius: 6,
  position: 'relative'
});

export default function FreeArticles ({ subscription }) {
  const navigate = useNavigate();

  const handleReadMoreClick = article => () => {
    const url = buildInternalArticleUrl(article, subscription);
    navigate(url);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      spacing={3}
    >
      {
        subscription.freeArticles && subscription.freeArticles.map(article => (
          <Grid xs={12} sm={6} md={4} item key={article.id}>
            <Article>
              <Typography variant='h2' sx={{ marginBottom: 2 }}>
                {article.title}
              </Typography>
              <Truncate
                line={3}
                text={article.summary}
              />
              <Box sx={{ position: 'absolute', bottom: 20, right: 20 }}>
                <StoreButton
                  onClick={handleReadMoreClick(article)}
                  label='read'
                />
              </Box>
            </Article>
          </Grid>
        ))
      }
    </Grid>
  );
}
