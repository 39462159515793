import React from 'react';
import {
  Paper,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';

export default function NoActiveSubscription ({ onStoreClick }) {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant='h4' sx={{ marginBottom: 3 }}>
        {t('no-active-subscriptions')}
      </Typography>
      <div>
        <StoreButton
          onClick={onStoreClick}
          label='browse-subscriptions'
        />
      </div>
    </Paper>
  );
}
