import React from 'react';
import HeroComponent from '../../Components/Sales/Hero.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: '#F3ECDE',
    paddingTop: '12rem',
    paddingBottom: '16rem',
    height: '70rem',
    [theme.breakpoints.down('lg')]: {
      '& img': {
        // mild CSS bastardry to avoid awkwardly cutting off the image holding the hand
        // each of these lines provides:
        // a horizontal gradient fading out the bottom of the image
        maskImage: 'linear-gradient(to top, transparent 0%, rgb(0, 0, 0) 5%), ' +
        // a vertical gradient fading out the right hand side of the image
        'linear-gradient(to left, transparent 0%, rgb(0, 0, 0) 10%), ' +
        // a diagonal gradient rounding off the bottom right corner so it looks less bad
        'linear-gradient(to top left, transparent 0%, rgb(0, 0, 0) 15%, rgb(0,0,0) 20%)',
        // this allows the individual masks to work together
        maskComposite: 'intersect'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '4rem',
      height: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8rem',
      '& img': {
        // this sets the width of the image so the shoulder of the image
        // just about sticks outside of the frame
        width: '123%'
      }
    }
  }
}));

const Hero = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HeroComponent
        title={t('an-unmatched-learning-experience')}
        body={t('each-email-mini-lesson')}
        imageSrc='/img/components/sales/hand-iphone-1.png'
      />
    </div>
  );
};

export default Hero;
