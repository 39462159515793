import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  Box
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const ContainerBox = styled(Box)({
  backgroundColor: '#ffffff',
  padding: 2,
  borderRadius: 18,
  display: 'inline-block'
});

export default function SubscriptionChip ({ subscribed }) {
  const { t } = useTranslation();

  return (
    <ContainerBox>
      <Chip
        label={subscribed ? t('subscribed') : t('subscription')}
        color='blue'
        icon={subscribed ? (<VerifiedIcon />) : undefined}
        variant={subscribed ? undefined : 'outlined'}
      />
    </ContainerBox>
  );
}
