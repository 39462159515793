import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Skeleton,
  Grid
} from '@mui/material';
import SearchResultsSkeleton from './SearchResultsSkeleton.js';

const useStyles = makeStyles((theme) => ({
  productDetails: {
    marginBottom: '8rem',
    marginTop: '-1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem'
    }
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  title: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 0
    }
  },
  tags: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  description: {
    marginBottom: '2rem',
    display: 'block'
  },
  priceAndBuyButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    padding: '3rem 0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '2rem 0'
    }
  },
  buyButtons: {
    display: 'flex',
    gap: '1.5rem'
  },
  setsAndHands: {
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: '-1.5rem',
      width: 'calc(100% + 3rem)'
    }
  }
}));

const ProductSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={{ xs: 1, sm: 3 }}
        className={classes.productDetails}
      >
        <Grid item sm={5} className={classes.image}>
          <Skeleton variant='rectangle' sx={{ height: 490 }} />
        </Grid>
        <Grid item sm={7}>
          <div className={classes.tags}>
            <Skeleton variant='rectangle' sx={{ height: 40, marginBottom: 2 }} />
          </div>
          <Skeleton variant='rectangle' sx={{ height: 40, marginBottom: 2 }} />
          <div className={classes.priceAndBuyButtons}>
            <Skeleton variant='rectangle' sx={{ height: 50, marginBottom: 2, width: 100, marginRight: 2 }} />
            <Skeleton variant='rectangle' sx={{ height: 50, marginBottom: 2, width: 100, marginRight: 2 }} />
            <Skeleton variant='rectangle' sx={{ height: 50, marginBottom: 2, width: 100, marginRight: 2 }} />
          </div>
          <Skeleton variant='rectangle' sx={{ height: 150, marginBottom: 2 }} />
          <Skeleton variant='rectangle' />
        </Grid>
      </Grid>
      <SearchResultsSkeleton />
    </>
  );
};

export default ProductSkeleton;
