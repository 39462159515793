import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { ApolloConsumer } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { pbnQueries } from '../../../../graphql/queries';
import PBNUploader from './PBNUploader.js';

export default function PbnManager ({ parentId, parentType, onProcessingComplete }) {
  const [isLoading, setIsLoading] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const [filename, setFilename] = useState(null);

  const [pbnQuery] = useLazyQuery(pbnQueries.PbnByFilename, {
    fetchPolicy: 'no-cache'
  });

  const onDataFetched = (data) => {
    if (pollCount === 30) {
      setPollCount(0);
      setIsLoading(false);
      onProcessingComplete('The upload has timed out');
    } else {
      setPollCount(pollCount + 1);

      // should we keep polling ?
      if (data.pbnByFilename === null || data.pbnByFilename.status === 'processing') {
        // wait 1 seconds and try again
        setTimeout(() => {
          poll();
        }, 1000);
      } else {
        // check status of most recent pbn
        const mostRecentPbn = data.pbnByFilename;
        if (mostRecentPbn.status === 'complete') {
          onProcessingComplete();
        } else {
          // it failed
          onProcessingComplete(`Converting PBN failed: ${mostRecentPbn.error}`);
        }
        setIsLoading(false);
        setPollCount(0);
      }
    }
  };

  const poll = async () => {
    const { data } = await pbnQuery({
      variables: { filename }
    });
    onDataFetched(data);
  };

  const handleUploadError = (message) => {
    onProcessingComplete(message);
  };

  const handleUploadFileNameGenerated = async (filename) => {
    setIsLoading(true);
    setFilename(filename);
  };

  return (
    <div>
      <PBNUploader
        onUploadComplete={poll}
        onUploadFilenameGenerated={handleUploadFileNameGenerated}
        onUploadError={handleUploadError}
        parentId={parentId}
        parentType={parentType}
        isLoading={isLoading}
      />
    </div>
  );
}

// PbnManager.propTypes = {
//   setId: PropTypes.number.isRequired,
//   onProcessingComplete: PropTypes.func.isRequired
// };
