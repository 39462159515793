import React, { useContext } from 'react';
import Hand from './Hand.js';
import { useOrientation } from '../../../../Hooks';
import {
  HandContext
} from '../../../../Context';

export default function HandDetails () {
  const {
    parent,
    hand,
    handleStepTaken,
    handleHandComplete,
    linkId
  } = useContext(HandContext);

  const isPortrait = useOrientation();

  if (!hand) {
    return null;
  }

  return (
    <Hand
      key={hand.id} // required to remount
      isPortrait={isPortrait}
      parent={parent}
      hand={hand}
      linkId={linkId}
      isPreview={false}
      onStepTaken={handleStepTaken}
      onHandComplete={handleHandComplete}
      startStep={
        hand.userHands && hand.userHands.length > 0
          ? hand.userHands[0].step
          : null
      }
    />
  );
}
