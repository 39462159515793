import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ProductSearch from '../../../Components/Queries/ProductSearch.js';
import SearchResultsSkeleton from '../../../Components/Skeletons/SearchResultsSkeleton.js';
import Heading from '../../../Components/Store/Heading.js';
import ListItem from '../../../Components/Store/ListItem.js';
import SortByDropDown from '../../../Components/Store/SortByDropDown.js';
import NoResultsMessage from '../../../Components/Store/NoResultsMessage.js';
import Tags from '../../../Components/Queries/Tags.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { getTagsByGroup } from '../../../helpers/tag.js';
import { stripNonAlphaNumeric } from '../../../helpers/global.js';
import { UserContext } from '../../../Context/UserContext.js';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  sort: {
    position: 'absolute',
    right: 0,
    top: '-1rem',
    '@media all and (max-width: 770px)': {
      top: '5rem',
      position: 'inherit'
    }
  },
  textList: {
    padding: 0,
    fontSize: 16,
    margin: '1rem 0 0 2rem',
    '& li': {
      padding: 0,
      margin: '0 0 0.5rem 0',
      '& a': {
        color: '#333'
      }
    }
  },
  heading: {
    marginBottom: '3rem'
  },
  tagList: {
    margin: '2rem 0'
  }
}));

const SearchResults = () => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const [orderBy, setOrderBy] = useState('product.title');
  const [orderByDirection, setOrderByDirection] = useState('asc');

  const qsValues = queryString.parse(location.search);
  const searchTerm = stripNonAlphaNumeric(qsValues.search);

  const handleSortChange = (e) => {
    const orderBy = e.target.value;
    let orderByDirection = 'asc';
    setOrderBy(orderBy);
    if (orderBy === 'product.createdAt') {
      orderByDirection = 'desc';
    }
    setOrderByDirection(orderByDirection);
  };

  const searchOptions = {
    searchTerm,
    limit: 1000,
    offset: 0, // page * limit,
    orderBy,
    orderByDirection,
    system: '',
    difficulty: '',
    type: ''
  };

  return (
    <div style={{ position: 'relative' }}>
      <ProductSearch options={searchOptions} user={user} loaderComponent={<SearchResultsSkeleton numOfItems={10} />}>
        {(products) => (
          <>
            {
              products.length > 0
                ? (
                  <>
                    <div className={classes.heading}>
                      <Heading
                        variant='h3'
                        text={[products.length, 'products-have-been-found-matching', searchTerm]}
                      />
                      <div className={classes.sort}>
                        <SortByDropDown
                          onChange={handleSortChange}
                          value={orderBy}
                        />
                      </div>
                    </div>
                    <div>
                      {
                      products.map((product, index) => {
                        return (
                          <ListItem
                            product={product}
                            key={index}
                          />
                        );
                      })
                    }
                    </div>
                    {/* invisible results items to push the footer down. Change number below for max invisible items to show */}
                    <SearchResultsSkeleton numOfItems={5 - products.length} invisible />
                  </>
                  )
                : (
                  <>
                    <NoResultsMessage text={['your-search-didnt-return-results', searchTerm]} />
                    <Grid
                      container
                      justifyContent='center'
                      spacing={4}
                    >
                      <Grid item md={3} sx={{ m: 3 }}>
                        <Typography variant='h3' className={classes.heading}>
                          <BBTranslate textId='try-searching-by-author-etc' />
                        </Typography>
                        <ul className={classes.textList}>
                          <li><BBTranslate textId='try-searching-by-author-etc' /></li>
                          <li><BBTranslate textId='check-your-spelling' /></li>
                          <li><BBTranslate textId='broaden-your-search' /></li>
                        </ul>
                      </Grid>
                      <Grid item md={6} sx={{ m: 3 }}>
                        <Typography variant='h3' className={classes.heading}>
                          <BBTranslate textId='see-our-content-matching' />
                        </Typography>
                        <Grid
                          container
                          justifyContent='space-between'
                          spacing={2}
                        >
                          <Tags group='all'>
                            {data => {
                              const systems = getTagsByGroup(data, 'System');
                              const difficulties = getTagsByGroup(data, 'Difficulty');
                              const types = getTagsByGroup(data, 'Type');
                              return (
                                <>
                                  <Grid item xs={12} sm className={classes.tagList}>
                                    <strong><BBTranslate textId='system' /></strong>
                                    <ul className={classes.textList}>
                                      {
                                      systems.map((item, index) => {
                                        return (
                                          <li key={index}>
                                            <Link to={`/search?search=${item.name}`}>
                                              <BBTranslate textId={item.name.toLowerCase()} />
                                            </Link>
                                          </li>
                                        );
                                      })
                                    }
                                    </ul>
                                  </Grid>
                                  <Grid item xs={12} sm className={classes.tagList}>
                                    <strong><BBTranslate textId='difficulty' /></strong>
                                    <ul className={classes.textList}>
                                      {
                                      difficulties.map((item, index) => {
                                        return (
                                          <li key={index}>
                                            <Link to={`/search?search=${item.name}`}>
                                              <BBTranslate textId={item.name.toLowerCase()} />
                                            </Link>
                                          </li>
                                        );
                                      })
                                    }
                                    </ul>
                                  </Grid>
                                  <Grid item xs={12} sm className={classes.tagList}>
                                    <strong><BBTranslate textId='product-type' /></strong>
                                    <ul className={classes.textList}>
                                      {
                                      types.map((item, index) => {
                                        return (
                                          <li key={index}>
                                            <Link to={`/search?search=${item.name}`}>
                                              <BBTranslate textId={item.name.toLowerCase()} />
                                            </Link>
                                          </li>
                                        );
                                      })
                                    }
                                    </ul>
                                  </Grid>
                                </>
                              );
                            }}
                          </Tags>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                  )
            }
          </>
        )}
      </ProductSearch>
    </div>
  );
};

export default SearchResults;
