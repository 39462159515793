import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import FeaturesListItem from './FeaturesListItem.js';

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }
  },
  item: {
    '&:first-child': {
      borderLeft: 0
    },
    paddingRight: '4rem',
    borderLeft: '1px #A09896 solid',
    [theme.breakpoints.down('md')]: {
      borderLeft: 0,
      paddingRight: 16,
      '& > div': {
        paddingBottom: '4rem',
        paddingTop: '4rem',
        borderBottom: '1px #A09896 solid'
      },
      '&:last-child > div': {
        borderBottom: 0,
        paddingBottom: 0
      }
    }
  }
}));

const FeaturesList = (props) => {
  const { features } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.itemsContainer}
      columnSpacing={{ xs: 2, md: 5 }}
      rowSpacing={0}
    >
      {features.map(feature => (
        <Grid
          className={classes.item}
          item
          xs={12}
          md={4}
          key={feature.heading}
        >
          <FeaturesListItem
            heading={feature.heading}
            body={feature.body}
            icon={feature.icon}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesList;
