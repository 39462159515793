import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Tooltip, Badge } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from '../Chat/Avatar.js';
import { useOrientation } from '../../../Hooks';
import { HandContext } from '../../../Context';

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&:hover $toggle': {
      color: '#333'
    }
  },
  headerExpanded: {
    position: 'relative',
    background: '#fff',
    width: '45rem',
    height: '7rem',
    padding: '0 1rem',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1rem',
      right: 0,
      height: '1rem',
      background: 'radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.1), transparent)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 1rem',
      pointerEvents: 'none'
    }
  },
  header: {
    position: 'relative',
    padding: '1rem',
    width: '7rem'
  },
  headerMobile: {
    width: '100%',
    height: '6rem',
    padding: '0 0.5rem',
    fontSize: '0.8em',
    cursor: 'default'
  },
  isPortrait: {
    width: '100%'
  },
  toggle: {
    color: '#999'
  },
  toggleIcon: {
    fontSize: '5rem'
  },
  author: {
    display: 'block',
    fontSize: '1.2em'
  },
  avatar: {
    width: '5rem',
    height: '5rem'
  }
});

export default function Header ({ open, onToggleDrawer, unreadMessages }) {
  const classes = useStyles();
  const isPortrait = useOrientation();

  const {
    hand,
    parent,
    getAuthor
  } = useContext(HandContext);

  const author = getAuthor();

  return (
    <div
      className={clsx(classes.container, open ? classes.headerExpanded : classes.header, isPortrait && classes.headerMobile)}
      onClick={isPortrait ? null : onToggleDrawer}
    >
      <Grid
        container
        direction={(open || isPortrait) ? 'row' : 'column'}
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <Grid item>
          <Badge badgeContent={unreadMessages} invisible={open || unreadMessages < 1} color='secondary'>
            <div className={classes.avatar}>
              <Avatar
                authorName={author.fullName}
                avatar={author.avatarPath}
              />
            </div>
          </Badge>
        </Grid>
        {
          (open || isPortrait) && (
            <Grid item xs zeroMinWidth>
              <div className={classes.author}>{author.fullName}</div>
              <Tooltip title={<Typography>{parent.title}: {hand.title}</Typography>}>
                <Typography
                  variant='inherit'
                  component='div'
                  noWrap
                  className={classes.setTitle}
                >
                  {hand.title}
                </Typography>
              </Tooltip>
            </Grid>
          )
        }
        {
          !isPortrait && (
            <Grid item>
              <Tooltip
                title={
                  <Typography>
                    {open ? 'Hide ' : 'Show '}
                    messages
                  </Typography>
                }
              >
                <div className={classes.toggle}>
                  {
                    open
                      ? <ChevronRightIcon className={classes.toggleIcon} />
                      : <ChevronLeftIcon className={classes.toggleIcon} />
                  }
                </div>
              </Tooltip>
            </Grid>
          )
        }
      </Grid>
    </div>
  );
}

Header.propTypes = {
  onToggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  unreadMessages: PropTypes.number.isRequired
};
