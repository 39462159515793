import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../graphql';
import { makeStyles } from '@mui/styles';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import BBFormContainer from '../../../Components/Forms/BBFormContainer.js';
import FormControl from '@mui/material/FormControl';
import BBSubmit from '../../../Components/Forms/BBSubmit.js';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext.js';
import { parseError } from '../../../helpers/error.js';
import { getEnvVar } from '../../../helpers/env.js';
import { persistJwt } from '../../../helpers/auth.js';
import {
  manageLocaleCookie,
  convertGraphQLLanguageName
} from '../../../helpers/locale.js';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  buttons: {
    textAlign: 'right',
    width: '100%'
  }
}));

const PurchaseDetails = ({ purchase }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(purchase.status);
  const [impersonateUser] = useMutation(mutations.ImpersonateUser);
  const [refundPurchase] = useMutation(mutations.RefundPurchase);

  const { openSnackbar } = useContext(BBSnackbarContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const handleImpersonateClick = () => {
    impersonateUser({
      variables: { userId: purchase.user.id }
    }).then((response) => {
      if (response.data && response.data.impersonateUser) {
        const impersonateUser = response.data.impersonateUser;
        persistJwt(impersonateUser.token);
        manageLocaleCookie('write', {
          language: convertGraphQLLanguageName(purchase.user.language),
          currency: purchase.user.currency
        });
        navigate('/impersonate?return=sales');
      } else {
        alert('User cannot be found');
      }
    }).catch(err => {
      alert(`error: ${err.message}`);
    });
  };

  const handleBackClick = () => {
    navigate('/admin/sales');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    refundPurchase({
      variables: { status: 'Refunded', id: purchase.id }
    }).then(() => {
      setStatus('Refunded');
      openSnackbar('Successfully refunded', 'success');
    }).catch(err => {
      const parsedError = parseError(err);
      openSnackbar('Error submitting form: ' + parsedError.message, 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const stripeDashboardUrl = getEnvVar('STRIPE_DASHBOARD_URL');

  return (
    <BBFormContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Id</TableCell><TableCell>{purchase.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Stripe Id</TableCell>
            <TableCell>
              <a target='_blank' rel='noopener noreferrer' href={`${stripeDashboardUrl}payments/${purchase.stripeId}`}>{purchase.stripeId}</a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Voucher Code</TableCell>
            <TableCell>
              {purchase.voucher ? purchase.voucher.code : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Purchase Date</TableCell><TableCell>{purchase.purchaseDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell><TableCell>{purchase.status}</TableCell>
          </TableRow>
          {
            purchase.status === 'Refunded' &&
              <TableRow>
                <TableCell>Refund Date</TableCell><TableCell>{purchase.refundDate}</TableCell>
              </TableRow>
          }
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>
              {purchase.user.fullName}
              <IconButton onClick={handleImpersonateClick} sx={{ marginLeft: 2 }}>
                <TheaterComedyIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Price</TableCell><TableCell>{purchase.totalPriceFormatted}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.buttons}>
        <form onSubmit={handleSubmit}>
          <FormControl variant='standard' margin='normal' style={{ marginRight: 10 }}>
            <Button variant='outlined' onClick={handleBackClick}>Back</Button>
          </FormControl>
          {
            status === 'Succeeded'
              ? (
                <FormControl variant='standard' margin='normal'>
                  <BBSubmit
                    label='Refund'
                    disabled={isLoading}
                    isLoading={isLoading}
                  />
                </FormControl>
                )
              : null
          }
        </form>
      </div>

    </BBFormContainer>
  );
};

PurchaseDetails.propTypes = {
  refundPurchase: PropTypes.func,
  purchase: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  onCancelClick: PropTypes.func
};

export default PurchaseDetails;
