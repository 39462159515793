import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Avatar from './Avatar.js';
import {
  HandContext
} from '../../../Context';

const useStyles = makeStyles({
  content: {
    display: 'inline-block',
    background: '#e5e5e5',
    padding: '0.8rem 1.4rem 0.8rem 1.2rem',
    maxWidth: '30rem',
    margin: '0.1rem 0 0.1rem 5rem',
    borderRadius: '0.3rem 1rem 1rem 0.3rem',
    '& p': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  contentRight: {
    float: 'right',
    border: '0.1rem solid #ddd',
    borderRadius: '1rem 1rem 0.3rem 1rem !important',
    padding: '0.8rem 1.2rem 0.8rem 1.4rem',
    background: '#fff',
    boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)'
  },
  avatar: {
    display: 'none',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '4rem',
    height: '4rem'
  },
  li: {
    position: 'relative',
    clear: 'both',
    '&:first-child': {
      '& > div:last-child': {
        borderRadius: '1rem 1rem 1rem 0.3rem'
      }
    },
    '&:last-child': {
      '& > div:first-child': {
        display: 'block !important'
      },
      '& > div:last-child': {
      }
    }
  }
});

export default function MessageWrapper ({ children, position }) {
  const classes = useStyles();

  const { getAuthor } = useContext(HandContext);
  const author = getAuthor();

  return (
    <li className={classes.li}>
      {
        author && position !== 'right'
          ? <div className={classes.avatar}><Avatar avatar={author.avatarPath} authorName={author.fullName} /></div>
          : null
      }
      <div
        className={
          clsx(
            classes.content,
            position === 'right' ? classes.contentRight : null
          )
        }
      >
        {children}
      </div>
    </li>
  );
}

MessageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired
};
