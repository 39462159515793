import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert
} from '@mui/material';

const FeedbackMessage = ({ message, type }) => {
  if (message === '') {
    return null;
  }
  return (
    <Alert
      severity={type || 'success'}
      sx={{ m: 3, border: 'solid 1px', borderRadius: '2px' }}
    >
      {message}
    </Alert>
  );
};

FeedbackMessage.protoTypes = {
  type: PropTypes.string.isRequired
};

export default FeedbackMessage;
