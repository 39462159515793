import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useOrientation, useComponentScale, useCardVariant } from '../../../Hooks';
import Card from './Card.js';
import {
  getCardsForSeat,
  arrangeSeatsBasedOnViewPoint
} from '../../../helpers/card.js';

const useStyles = makeStyles(() => ({
  middle: {
    position: 'relative',
    width: '28.4em',
    minWidth: '28.4em',
    height: '26em',
    minHeight: '26em',
    fontSize: 0,
    '@media (orientation: portrait)': {
      width: '19em',
      minWidth: '19em',
      height: '40em',
      minHeight: '40em'
    }
  },
  nl: {
    width: '25.7em',
    minWidth: '25.7em',
    '& $card': {
      width: '8.1em'
    },
    '& $index0': {
      marginLeft: '-4.05em'
    },
    '& $index2': {
      marginLeft: '-4.05em'
    }
  },
  card: {
    position: 'absolute',
    width: '9em',
    height: '12.6em',
    background: 'rgba(0,0,0,0.1)',
    border: '0.1em solid rgba(0,0,0,0.25)',
    borderRadius: '0.5em',
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
    transitionProperty: 'border-color, background-color',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      transitionProperty: 'opacity',
      transitionDuration: '0.4s'
    }
  },
  highlightedCard: {
    background: 'rgba(0,0,0,0)',
    boxShadow: '0 0 0 0.4em rgba(255,255,255,0.1)'
  },
  index0: {
    left: '50%',
    top: 0,
    marginLeft: '-4.5em'
  },
  index1: {
    right: 0,
    top: '50%',
    marginTop: '-6.3em'
  },
  index2: {
    left: '50%',
    bottom: 0,
    marginLeft: '-4.5em'
  },
  index3: {
    left: 0,
    top: '50%',
    marginTop: '-6.3em'
  },
  direction: {
    color: '#fff',
    textAlign: 'center',
    display: 'block',
    fontSize: '1.3em',
    lineHeight: '2em',
    fontWeight: 'bold',
    transition: 'inherit',
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  winner: {
    borderColor: '#f5a623',
    '&:after': {
      background: 'url("/img/hand/middle_trick_winner.svg") no-repeat center center',
      opacity: 1
    }
  },
  arrow: {
    position: 'absolute',
    background: 'url("/img/hand/play_middle_arrow_top.svg") no-repeat center center',
    backgroundSize: 'contain'
  },
  arrowIndex0: {
    top: '-3em',
    left: 0,
    right: 0,
    height: '2.5em'
  },
  arrowIndex1: {
    top: 0,
    right: '-3em',
    bottom: 0,
    width: '2.5em',
    backgroundImage: 'url("/img/hand/play_middle_arrow_right.svg")'
  },
  arrowIndex2: {
    bottom: '-3em',
    left: 0,
    right: 0,
    height: '2.5em',
    backgroundImage: 'url("/img/hand/play_middle_arrow_bottom.svg")'
  },
  arrowIndex3: {
    top: 0,
    left: '-3em',
    bottom: 0,
    width: '2.5em',
    backgroundImage: 'url("/img/hand/play_middle_arrow_left.svg")'
  }
}));

export default function Trick ({ trick, viewpoint, trickRefCallback, activeSeat }) {
  const directions = arrangeSeatsBasedOnViewPoint(viewpoint);
  const classes = useStyles();

  const isPortrait = useOrientation();

  const componentScale = useComponentScale();
  let scale = componentScale / 0.9;

  if (isPortrait) {
    scale = componentScale;
  }

  const cardVariant = useCardVariant();

  return (
    <div
      className={clsx(classes.middle, classes[cardVariant])}
      style={{ fontSize: `${scale}rem` }}
    >
      {
      directions.map((direction, index) => {
        const card = getCardsForSeat(trick, direction.value)[0];
        return (
          <React.Fragment key={index}>
            <div
              className={clsx(
                classes.card,
                classes[`index${index}`],
                card && card.winner ? classes.winner : null,
                activeSeat === direction.value ? classes.highlightedCard : null
              )}
              ref={trickRefCallback}
              id={direction.value}
            >
              {
                card
                  ? <Card
                      value={`${card.rank}${card.suit}`}
                      enabled
                      variant={cardVariant}
                    />
                  : null
              }
            </div>
            {
              activeSeat === direction.value
                ? <div className={clsx(classes.arrow, classes[`arrowIndex${index}`])} />
                : null
            }
          </React.Fragment>
        );
      })
    }
    </div>
  );
}

Trick.propTypes = {
  trick: PropTypes.array.isRequired,
  viewpoint: PropTypes.string.isRequired,
  trickRefCallback: PropTypes.func,
  activeSeat: PropTypes.string
};
