import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import StoreInput from '../../../Components/Forms/Store/StoreInput.js';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import NiceError from '../../../Components/Errors/NiceError.js';
import { persistJwt } from '../../../helpers/auth.js';
import { mutations } from '../../../graphql';
import { UserContext } from '../../../Context';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  error: {
    color: theme.palette.error.main,
    padding: 5
  },
  button: {
    // marginTop: 20,
    textAlign: 'right'
  }
}));

const LoginForm = ({ onLoginComplete }) => {
  const classes = useStyles();
  const { setUser } = useContext(UserContext);
  const [fields, setFields] = useState({
    email: '',
    password: ''
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [error, setError] = useState(null);

  const hasAutoFilled = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [loginUser] = useMutation(mutations.LoginUser);

  const validate = useCallback(() => {
    let isValid = true;
    const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

    if (!fields.password) {
      isValid = false;
    }
    if (!fields.email) {
      isValid = false;
    }
    if (errMessages.length) {
      isValid = false;
    }
    setButtonDisabled(!isValid);

    if (isValid) {
      hasAutoFilled.current = true;
    }
    return isValid;
  }, [fields, fieldErrors, hasAutoFilled]);

  const handleInputChange = ({ id, value, error }) => {
    setFields((state) => ({ ...state, [id]: value }));
    setFieldErrors((state) => ({ ...state, [id]: error }));
    validate();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (validate()) {
      try {
        const response = await loginUser({
          variables: fields
        });
        const user = response.data.loginUser;

        // persist jwt token to cookie
        persistJwt(user.token);

        // set user context
        setUser(user);

        // redirect to relevant path (unless logging in via Checkout)
        if (user.type !== 'Customer') {
          navigate('/admin');
        } else if (location.pathname === '/' ||
          location.pathname.includes('resetpassword') ||
          location.pathname.includes('login')
        ) {
          // sometimes we may want to redirect after login...
          // e.g if on the password reset page, or home page, or login page
          navigate('/library');
        }
        onLoginComplete && onLoginComplete();
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    }
  };

  // Make sure validate function is called if user auto-fills form fields
  // Use hasAutoFilled ref to track if this has already happened so validate() doesn't get called repetitively
  useEffect(() => {
    if (!hasAutoFilled.current) {
      validate();
    }
  }, [fields, validate, hasAutoFilled]);

  return (
    <form action='' className={classes.form}>
      <NiceError err={error} defaultText='logging-you-in' />
      <FormControl variant='standard' margin='normal' required fullWidth>
        <StoreInput
          id='email'
          label='email-address'
          autoComplete='email'
          autoFocus
          required
          maxLength={100}
          value={fields.email}
          onChange={handleInputChange}
          validate={val => (validator.isEmail(val) ? '' : 'Invalid Email')}
        />
      </FormControl>
      <FormControl variant='standard' margin='normal' required fullWidth>
        <StoreInput
          id='password'
          type='password'
          label='password'
          autoComplete='current-password'
          required
          maxLength={100}
          value={fields.password}
          onChange={handleInputChange}
          validate={val => (val ? '' : 'Password is required')}
        />
      </FormControl>
      <div className={classes.button}>
        <FormControl variant='standard' margin='normal'>
          <StoreButton
            type='submit'
            disabled={isLoading || buttonDisabled} // || !this.validate()}
            loading={isLoading}
            onClick={handleSubmit}
            label='login'
          />
        </FormControl>
      </div>
    </form>
  );
};

export default LoginForm;
