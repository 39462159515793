import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { mutations } from '../../../graphql';
import { persistJwt } from '../../../helpers/auth.js';
import {
  manageLocaleCookie,
  convertGraphQLLanguageName
} from '../../../helpers/locale.js';

const ImpersonateUser = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dontImpersonate] = useMutation(mutations.DontImpersonateUser, {
    onCompleted: (data) => {
      if (data.dontImpersonateUser) {
        const adminUser = data.dontImpersonateUser;
        persistJwt(adminUser.token);
        manageLocaleCookie('write', {
          language: convertGraphQLLanguageName(adminUser.language),
          currency: adminUser.currency
        });
        // get the area to return to
        const qsValues = queryString.parse(location.search);
        navigate(`/admin/${qsValues.return}`);
      } else {
        alert('Admin user cannot be found');
      }
    }
  });
  const handleDontImpersonateUser = () => {
    dontImpersonate();
  };
  return (
    <>
      <div style={{ width: '100%', height: '80px', padding: 20, borderBottom: 'solid 4px red', backgroundColor: '#ffffff' }}>
        <Button color='secondary' onClick={handleDontImpersonateUser} variant='contained'>
          Return to Admin
        </Button>
        <Typography variant='caption'>&nbsp;&nbsp; * You must click the 'Return to admin' button when you are finished impersonating a user otherwise you will be logged in as them during your next session </Typography>
      </div>
      <iframe src='/' title='Impersonate User' width='100%' height='1000px' />
    </>
  );
};

export default ImpersonateUser;
