import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Grid
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { subscriptionQueries } from '../../../graphql/queries';
import { formatURLTitle } from '../../../helpers/http.js';

function BasicTable ({ rows }) {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Author</TableCell>
            <TableCell align='right'>Title</TableCell>
            <TableCell align='right'>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              key={row.id}
              onClick={() => navigate(`${row.id}/${formatURLTitle(row.title)}`)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {row.author.fullName}
              </TableCell>
              <TableCell align='right'>{row.title}</TableCell>
              <TableCell align='right'>{row.onSale ? 'Active' : 'Inactive'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function BasicButtons (props) {
  const navigate = useNavigate();

  return (
    <Stack spacing={2} direction='row' onClick={() => navigate(props.navigateDestination)}>
      <Button variant='contained'>Add subscription</Button>
    </Stack>
  );
}

const SubscriptionSearch = () => {
  const { data } = useQuery(subscriptionQueries.SubscriptionsAdmin, {
    fetchPolicy: 'no-cache'
  });

  // const [searchQuery, setSearchQuery] = useState('');

  if (!data) {
    return null;
  }

  return (
    <div>
      <Grid container rowSpacing={6} columnSpacing={2}>
        <Grid item xs={9.5}>
          {/* <TextField
            label='Search'
            variant='standard'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          /> */}
        </Grid>
        <Grid item xs={2.5}>
          <BasicButtons navigateDestination='add' />
        </Grid>
        <Grid item xs={12}>
          <BasicTable rows={data.subscriptionsAdmin} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SubscriptionSearch;
