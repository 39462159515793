import React, { useState, useContext } from 'react';
import { Outlet, useNavigate, useMatches } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  useMediaQuery
} from '@mui/material';
import Container from '../../../Components/Store/Container.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import NiceError from '../../../Components/Errors/NiceError.js';
import FeedbackMessage from '../../../Components/FeedbackMessage.js';
import { UserContext } from '../../../Context';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '3rem'
  },
  box: {
    borderBottom: 1,
    borderColor: 'divider',
    marginTop: '3rem'
  },
  tabContainer: {
    [theme.breakpoints.down('md')]: {
      background: '#fff'
    }
  },
  tabContent: {
    backgroundColor: '#fff',
    padding: '3rem',
    minHeight: 300
  },
  // we want the scrolled tab cutoff to be transparent,
  // so we mask away the contents of the tab scroller:
  //
  // ---------------------------------------------
  // | 1 |                2                  | 3 |
  // ---------------------------------------------
  //
  // 1 - gradient going between invisible and visible (1rem wide)
  // 2 - fully visible
  // 3 - gradient going between visible and invisible (1rem wide)
  tabScroller: {
    [theme.breakpoints.down('md')]: {
      maskImage: 'linear-gradient(to right, transparent 0rem, rgb(0, 0, 0, 1) 1rem, rgb(0, 0, 0, 1) calc(100% - 1rem), transparent 100%)'
    }
  },
  // however we don't want the tabs themselves to be cut off with the gradient
  // (they have a solid bottom border spanning their whole width so it's extra obvious when this happens)
  // so we reduce the effective width of the left-hand scroll button...
  tabScrollButtons: {
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        marginRight: '-1rem'
      }
    }
  },
  tab: {
    backgroundColor: '#fff',
    // ... and add some spacing to the leftmost tab to make the left-hand scroll button
    // appear to take up the exact same space
    // (for some reason this trick doesn't work on the rightmost tab -
    // something to do with how the tab scroll is calculated)
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        marginLeft: '1rem'
      }
    }
  }
}));

const Account = () => {
  const { user, setUser, saveUser } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(null);

  const classes = useStyles();
  const theme = useTheme();
  const scrollableBreakpoints = useMediaQuery(theme.breakpoints.down('md'));

  const views = [
    { label: 'personal-details', path: '' },
    { label: 'payment-details', path: 'payment' },
    { label: 'change-password', path: 'password' },
    { label: 'purchase-history', path: 'purchases' },
    { label: 'subscriptions', path: 'subscriptions' }
  ];
  const navigate = useNavigate();
  const matches = useMatches();
  // set active tab from browser path
  const activeView = views.findIndex(view => {
    return matches.find(match => match.pathname === `/account/${view.path}`);
  });
  const [value, setValue] = React.useState(activeView);

  // do all the heavy lifting in the this component so we don't
  // need to repeat this stuff in each of the children
  // 1. calling the mutation (saveUser)
  // 2. managing loading state
  // 3. showing messages
  const handleSaveUser = async (updatedUser) => {
    setIsLoading(true);
    setError(null);
    setMessage('');
    const saveUserPromise = () => {
      return new Promise((resolve, reject) => {
        saveUser(updatedUser, (err, data) => {
          if (err) {
            reject(err);
          }
          setUser(data.data.updateUser);
          handleSubmitSuccess('Saved successfully');
          resolve();
        });
      });
    };

    try {
      await saveUserPromise(updatedUser);
    } catch (err) {
      handleSubmitError(err);
    }
    setIsLoading(false);
  };

  const handleSubmitError = (err) => {
    setMessage('');
    setError(err);
  };

  const handleSubmitSuccess = (message) => {
    setError(null);
    setMessageType('success');
    setMessage(message);
  };

  const handleWarningMessage = (message) => {
    setError(null);
    setMessage(message);
    setMessageType('warning');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!user) {
    return null;
  }

  function a11yProps (index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleNavClick = to => (e) => {
    navigate(`/account/${to}`);
  };

  return (
    <>
      <Container>
        <Box className={classes.heading}>
          <Typography variant='h1'><BBTranslate textId='my-account' /></Typography>
        </Box>
        <FeedbackMessage message={message} type={messageType} />
        <NiceError err={error} defaultText='updating-your-account' />
        <Box className={scrollableBreakpoints ? classes.tabContainer : ''}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={scrollableBreakpoints ? 'scrollable' : 'standard'}
            classes={{
              scroller: classes.tabScroller,
              scrollButtons: classes.tabScrollButtons
            }}
            scrollButtons
            allowScrollButtonsMobile
          >
            {
              views.map((view, index) => (
                <Tab
                  key={index}
                  className={classes.tab}
                  label={<BBTranslate textId={view.label} />}
                  {...a11yProps(index)}
                  onClick={handleNavClick(view.path)}
                />
              ))
            }
          </Tabs>
        </Box>
        <Box className={classes.tabContent}>
          <Outlet context={{ user, handleSaveUser, handleSubmitError, handleSubmitSuccess, handleWarningMessage, isLoading }} />
        </Box>
      </Container>
    </>
  );
};

export default Account;
