// master page for all customer pages (store, account, library)
import React from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import AppBar from '../../Components/Layout/AppBar/AppBar.js';
import Footer from '../../Components/Layout/Footer.js';
import { makeStyles } from '@mui/styles';
import BBBreadcrumbs from '../../Components/Store/BBBreadcrumbs.js';
import VoucherBanner from '../../Components/Store/VoucherBanner.js';
import AddToHomescreen from '../../Components/AddToHomescreen.js';
import MainContainer from '../../Components/Layout/MainContainer.js';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflowX: 'hidden'
    }
  }
}));

const Customer = () => {
  useStyles();

  const matches = useMatches();
  const showVoucherBanner = matches.some(
    (match) => match.handle?.showVoucherBanner
  );

  return (
    <>
      <AppBar />
      <MainContainer>
        {showVoucherBanner && <VoucherBanner />}
        <BBBreadcrumbs />
        <Outlet />
      </MainContainer>
      <Footer />
      <AddToHomescreen />
    </>
  );
};

export default Customer;
