import React, { useState } from 'react';

const GiftContext = React.createContext();

const GiftProvider = ({ children }) => {
  const [gift, setGift] = useState(null);

  const initSubscriptionGift = (subscriptionPlan) => {
    console.log('subscriptionPlan.subscription.author.fullName', subscriptionPlan.subscription.author.fullName);
    console.log('subscriptionPlan.subscription.images.cover', subscriptionPlan.subscription.images.cover);
    setGift({
      item: {
        id: subscriptionPlan.id,
        title: `${subscriptionPlan.subscription.title} (${subscriptionPlan.type})`,
        images: subscriptionPlan.subscription.images,
        price: subscriptionPlan.price,
        author: subscriptionPlan.subscription.author.fullName,
        type: 'Subscription'
      }
    });
  };

  const initGift = (product) => {
    setGift({
      item: {
        ...product,
        author: product.author.fullName,
        type: 'Product'
      }
    });
  };

  return (
    <GiftContext.Provider value={{ gift, initGift, setGift, initSubscriptionGift }}>
      {children}
    </GiftContext.Provider>
  );
};

export { GiftContext, GiftProvider };
