import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import striptags from 'striptags';
import { mutations } from '../../../graphql';
import { useMutation } from '@apollo/client';
import {
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress
} from '@mui/material';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext.js';
import BBDatePicker from '../../../Components/BBDatePicker.js';
import AdminBackButton from '../../../Components/AdminBackButton.js';
import RichText from '../../../Components/Forms/RichText/RichText.js';
import SavingBackdrop from '../../../Components/Forms/SavingBackdrop';

function SelectDropdownHours ({ title, value, onChange }) {
  const optionsHours = [];
  for (let i = 0; i <= 24; i++) {
    const formattedNumber = i < 10 ? `0${i}` : i.toString();
    optionsHours.push(
      <MenuItem value={formattedNumber} key={formattedNumber}>
        {formattedNumber}
      </MenuItem>
    );
  }
  return (
    <FormControl fullWidth size='small'>
      <InputLabel>{title}</InputLabel>
      <Select
        value={value || ''}
        onChange={onChange}
        label={title}
      >
        {optionsHours}
      </Select>
    </FormControl>
  );
}

function SelectDropdownMin ({ title, value, onChange }) {
  const optionsMin = [];
  for (let i = 0; i <= 59; i += 5) {
    const formattedNumber = i < 10 ? `0${i}` : i.toString();
    optionsMin.push(
      <MenuItem value={formattedNumber} key={formattedNumber}>
        {formattedNumber}
      </MenuItem>
    );
  }
  return (
    <FormControl fullWidth size='small'>
      <InputLabel>{title}</InputLabel>
      <Select
        value={value || ''}
        onChange={onChange}
        label={title}
      >
        {optionsMin}
      </Select>
    </FormControl>
  );
}

export function ArticleForm ({ article, onSave }) {
  const [currentArticle, setCurrentArticle] = useState(article);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [waitingForSummary, setWaitingForSummary] = useState(false);
  const [aiSummary, setAiSummary] = useState(null);

  // publishDateTime
  const momentDate = article.publishDateTime && moment(currentArticle.publishDateTime);
  const [date, setDate] = useState(momentDate); // momentDate.format('YYYY-MM-DD');
  const [hour, setHour] = useState(momentDate && momentDate.format('HH'));
  const [minute, setMinute] = useState(momentDate && momentDate.format('mm'));

  const [upsertArticle] = useMutation(mutations.UpsertArticle);
  const [generateSummary] = useMutation(mutations.GenerateArticleSummary);
  const { openSnackbar } = useContext(BBSnackbarContext);

  const rootFontStyle = {
    fontSize: '20px'
  };

  // Validating for save article button
  const validate = (article) => {
    let isValid = false;
    if (article) {
      if (
        article.title &&
        article.title.length > 0 &&
        article.body &&
        striptags(article.body).length > 0 &&
        article.summary &&
        striptags(article.summary).length > 0 &&
        date &&
        date !== '' &&
        hour &&
        hour !== '' &&
        minute &&
        minute !== ''
      ) {
        isValid = true;
      }
    }
    return isValid;
  };

  useEffect(() => {
    setIsFormValid(validate(currentArticle));
  }, [currentArticle]);

  const handleTitleChange = (e) => {
    const updatedArticle = { ...currentArticle };
    updatedArticle.title = e.target.value;
    setCurrentArticle(updatedArticle);
  };

  const handleArticleChange = ({ value }) => {
    const updatedArticle = { ...currentArticle };
    updatedArticle.body = value;
    setCurrentArticle(updatedArticle);
  };

  const handleSummaryChange = ({ value }) => {
    const updatedArticle = { ...currentArticle };
    updatedArticle.summary = value;
    setCurrentArticle(updatedArticle);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
    updatePublishDateTime(newDate, hour, minute);
  };

  const handleHourChange = (event) => {
    const newHour = event.target.value;
    setHour(newHour);
    updatePublishDateTime(date, newHour, minute);
  };

  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setMinute(newMinute);
    updatePublishDateTime(date, hour, newMinute);
  };

  const updatePublishDateTime = (newDateCopy, newHourCopy, newMinuteCopy) => {
    // reversed Andrey's change as it didn't take into account undefined values (when adding a new article)
    // const newDateString = moment(newDateCopy)
    //   .hours(newHourCopy)
    //   .minutes(newMinuteCopy)
    //   .toISOString();

    // setCurrentArticle((prevState) => ({
    //   ...prevState,
    //   publishDateTime: newDateString,
    // }));

    const formattedNewDateCopy = moment(newDateCopy).format('YYYY-MM-DD');
    const newPublishDateTime = `${formattedNewDateCopy} ${newHourCopy}:${newMinuteCopy}`;

    setCurrentArticle((prevState) => ({
      ...prevState,
      publishDateTime: newPublishDateTime,
      publishDateTimeUniversal: moment(newPublishDateTime).format('MMMM Do YYYY HH:mm')
    }));
  };

  const handleSaveClick = async () => {
    setIsLoading(true);

    let response;
    try {
      response = await upsertArticle({
        variables: currentArticle
      });
      setIsLoading(false);
      openSnackbar('Saved successfully', 'success');
    } catch (e) {
      setIsLoading(false);
      openSnackbar(e.message, 'error');
    }

    if (onSave) {
      onSave(response.data.upsertArticle);
    }
  };

  const handleMagicButtonClick = async () => {
    setWaitingForSummary(true);
    const response = await generateSummary({
      variables: {
        body: currentArticle.body
      }
    });

    const summary = response.data.generateArticleSummary.summary;
    setCurrentArticle({ ...currentArticle, summary });
    setAiSummary(summary);
    setWaitingForSummary(false);
  };

  // console.log('currentArticle', currentArticle);

  if (!currentArticle) {
    return null;
  }

  return (
    <Paper>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
      >
        <Grid item xs={11}>
          <AdminBackButton destination={`/admin/subscriptions/${currentArticle.subscription.id}/${currentArticle.subscription.title}`} />
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            name='title'
            label='Title *'
            id='fullWidth'
            value={currentArticle.title}
            onChange={handleTitleChange}
            error={currentArticle.title === ''}
            helperText={currentArticle.title === '' ? 'Required field' : ''}
          />
        </Grid>
        <Grid item xs={11}>
          <RichText
            name='body'
            value={currentArticle.body}
            label='Body *'
            height={500}
            onChange={handleArticleChange}
            amberCharMin={500}
            redCharMin={1000}
          />
        </Grid>
        <Grid item xs={11}>
          <RichText
            name='summary'
            value={currentArticle.summary}
            aiSummaryHack={aiSummary}
            label='Summary *'
            height={200}
            onChange={handleSummaryChange}
            amberCharMin={100}
            redCharMin={2000}
            onMagicButtonClick={handleMagicButtonClick}
          />
        </Grid>
        <Grid item xs={4}>
          <div style={rootFontStyle}>Publish date time</div>
        </Grid>
        <Grid item xs={3}>
          <BBDatePicker
            date={date}
            onChange={handleDateChange} // (date) => handleDateChange(moment(date).format('YYYY-MM-DD'))
            label='Select Date'
          />
        </Grid>
        <Grid item xs={2}>
          <SelectDropdownHours title='Hour *' value={hour} onChange={handleHourChange} />
        </Grid>
        <Grid item xs={2}>
          <SelectDropdownMin title='Min *' value={minute} onChange={handleMinuteChange} />
        </Grid>
        <Grid item xs={11}>
          {isLoading
            ? (
              <CircularProgress size={24} />
              )
            : (
              <Button disabled={!isFormValid} variant='contained' onClick={handleSaveClick}>
                Save article
              </Button>
              )}
        </Grid>
        <Grid item xs={11}>
          <div />
        </Grid>
      </Grid>
      <SavingBackdrop open={waitingForSummary} text='Loading...' />
    </Paper>
  );
}
