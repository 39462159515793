import React from 'react';
import { makeStyles } from '@mui/styles';
import HighlightSection from '../../Components/Sales/HighlightSection.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '74rem',
    overflow: 'hidden',
    backgroundColor: '#8b8b78',
    // makes the purple BG on the text container extend
    // to the left edge of the viewport
    '&:before': {
      content: '" "',
      position: 'absolute',
      right: 'calc(50% + 59rem)',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: '#613344'
    },
    [theme.breakpoints.down('md')]: {
      height: '56rem',
      '&:before': {
        content: 'none'
      }
    }
  },
  contentContainer: {
    maxWidth: '118rem',
    margin: '0 auto',
    zIndex: 1,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  image: {
    backgroundImage: 'url(/img/components/sales/hand-iphone-3.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    height: '100%',
    position: 'absolute',
    width: 1000,
    left: '18%',
    right: 0,
    // this allows us to fade out the right-hand side of the image
    // so there's no clean edge
    maskImage: 'linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 1) 5%)',
    [theme.breakpoints.down('xl')]: {
      left: '30%'
    },
    [theme.breakpoints.down('lg')]: {
      left: '5%'
    },
    [theme.breakpoints.down('md')]: {
      maskImage: 'none',
      position: 'relative',
      left: 0,
      right: 0,
      width: '100%',
      height: '50%',
      flexGrow: 1,
      backgroundPosition: 'center'
    }
  },
  textContainer: {
    position: 'absolute',
    right: '60%',
    height: '100%',
    display: 'flex',
    padding: '5rem 5rem 5rem 1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#613344',
    // slight shadow fade at the right edge of the copy section
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 15,
      right: 0,
      backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '50%',
      left: 0,
      position: 'relative',
      padding: '4rem 2rem',
      '&:after': {
        content: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }
}));

const Highlight2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.image} />
        <div className={classes.textContainer}>
          <HighlightSection
            heading={t('its-like-having-private-lesson')}
            body={t('no-matter-what-level-you-are')}
          />
        </div>
      </div>
    </div>
  );
};

export default Highlight2;
