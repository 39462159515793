import _ from 'lodash';
import {
  formatPrice
} from './currency.js';
import { writeCookie, readCookie } from './cookie.js';

const env = process.env.REACT_APP_ENV;
const cartCookieName = `bb-cart-${env}`;

const addToCart = (cartItems, newItem) => {
  if (!cartItems) {
    cartItems = [];
  }
  cartItems.push(newItem);
  return cartItems;
};

const removeFromCart = (cartItems, id) => {
  const itemIndex = cartItems.findIndex((item) => item.id === id);
  if (itemIndex > -1) {
    cartItems.splice(itemIndex, 1);
  }
  return cartItems;
};

const persistCart = cart => {
  const cookieString = encodeURIComponent(JSON.stringify(cart));
  writeCookie(cartCookieName, cookieString);
};

const getCart = () => {
  const cookieString = readCookie(cartCookieName);
  if (cookieString) {
    return JSON.parse(decodeURIComponent(cookieString));
  }
  return null;
};

const getPriceAfterDiscount = (price, discount, discountType) => {
  let finalPrice = price;
  if (discount && discount > 0) {
    let discountPrice;

    if (discountType === 'Percentage') {
      discountPrice = (discount / 100) * price;
    } else if (discountType === 'FixedValue') {
      discountPrice = discount;
    }

    finalPrice = price - discountPrice;
  }
  return finalPrice;
};

const getProductPriceWithDiscountByCurrency = (currency, cartItem) => {
  return getPriceAfterDiscount(
    cartItem.price['price' + currency.toUpperCase()],
    cartItem.discount,
    cartItem.discountType
  );
};

const getProductPriceByCurrency = (currency, price) => {
  return price['price' + currency.toUpperCase()];
};

const checkIfFreeAfterDiscount = (currency, cartItems) => {
  let total = 0;
  cartItems.forEach(item => {
    total += parseFloat(getProductPriceWithDiscountByCurrency(currency, item));
  });
  return total === 0;
};

const getTotalBillableAmount = (currency, cartItems) => {
  let total = 0;
  cartItems.forEach(item => {
    total += parseFloat(getProductPriceWithDiscountByCurrency(currency, item));
  });
  return formatPrice(total, currency);
};

const giftItemsOnly = (cartItems) => {
  const gifts = _.filter(cartItems, (item) => item.gift);
  return gifts.length === cartItems.length;
};

const containsSubscription = (cartItems) => {
  const subscriptionItems = _.filter(cartItems, (item) => item.type === 'Subscription');
  return subscriptionItems && subscriptionItems.length > 0;
};

const getVoucherRemainingBalance = (voucher, cartItems, currency) => {
  if (!voucher || typeof voucher.balance !== 'number') {
    return null;
  }

  let total = 0;

  if (cartItems) {
    cartItems.forEach(item => {
      total += item.price['price' + currency.toUpperCase()];
    });
  }

  let balance = voucher.balance - total;
  balance = Math.max(balance, 0);

  return formatPrice(balance, currency);
};

export {
  addToCart,
  removeFromCart,
  persistCart,
  getCart,
  checkIfFreeAfterDiscount,
  getPriceAfterDiscount,
  getProductPriceWithDiscountByCurrency,
  getProductPriceByCurrency,
  getTotalBillableAmount,
  giftItemsOnly,
  containsSubscription,
  getVoucherRemainingBalance
};
