import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import SimpleChart from './SimpleChart';
import BBDateFilters from '../../../Components/BBDateFilters.js';
import { isAuthorised } from '../../../helpers/auth.js';
import { UserContext } from '../../../Context';

const useStyles = makeStyles({
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  }
});

const Dashboard = () => {
  const classes = useStyles();
  const [dateFrom, setDateFrom] = useState(moment().subtract(7, 'days').format());
  const [dateTo, setDateTo] = useState(moment().format());
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleDateFiltersChange = (changes) => {
    if (changes.from) {
      setDateFrom(moment(changes.from).format());
    }
    if (changes.to) {
      setDateTo(moment(changes.to).format());
    }
  };

  useEffect(() => {
    // if an Editor or Publisher is accessing the page
    // then redirect them to the only page
    // they can see which is the products page
    if (isAuthorised(['Editor', 'Publisher'], user)) {
      navigate('products');
    }
  }, [user, navigate]);

  if (isAuthorised(['Admin'], user)) {
    return (
      <>
        <div className={classes.appBarSpacer} />
        <Typography variant='h4' gutterBottom component='h2'>
          Orders
        </Typography>
        <BBDateFilters
          onChange={handleDateFiltersChange}
          to={dateTo}
          from={dateFrom}
        />
        <Typography component='div' className={classes.chartContainer}>
          <SimpleChart dateFrom={dateFrom} dateTo={dateTo} />
        </Typography>
      </>
    );
  } else {
    return null;
  }
};

export default Dashboard;
