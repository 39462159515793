import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { convertBidsToTableRows, getStepNumberFromTable } from '../../helpers/auction.js';
import MiniCard from './MiniCard/MiniCard.js';
import BBTranslate from '../Locale/BBTranslate.js';
import { getDirectionTextId } from '../../helpers/translation.js';
import { useOrientation } from '../../Hooks';

const useStyles = makeStyles({
  table: {
    textAlign: 'center',
    border: 'none',
    borderRadius: '0.3em',
    borderBottom: '1px solid #eee',
    userSelect: 'none',
    width: '100%',
    '& thead': {
      fontSize: '1.6em',
      '& tr': {
        '& th': {
          paddingBottom: '0.5em'
        }
      }
    },
    '& tbody': {
      '& tr': {
        background: '#fff',
        '& td': {
          padding: '0.4em 0.2em',
          borderTop: '0.1em solid #eee',
          minWidth: '5.5em',
          '&:first-child': {
            borderLeft: '0.1em solid #eee',
            paddingLeft: '0.4em'
          },
          '&:last-child': {
            borderRight: '0.1em solid #eee',
            paddingRight: '0.4em'
          },
          '& > span': {
            margin: '0 auto'
          }
        }
      }
    }
  },
  tablePortrait: {
    fontSize: '0.7rem !important',
    borderBottomColor: 'rgba(255,255,255,0.25)',
    '& tbody': {
      '& tr': {
        background: 'transparent',
        '& td': {
          borderTopColor: 'rgba(255,255,255,0.25)',
          '&:first-child': {
            borderLeft: 'none'
          },
          '&:last-child': {
            borderRight: 'none'
          }
        }
      }
    }
  }
});

export default function Auction ({ size, auction, dealer, onBidClick }) {
  const classes = useStyles();
  const isPortrait = useOrientation();

  // turn bids into rows
  const rows = convertBidsToTableRows(auction, dealer);
  const directions = ['W', 'N', 'E', 'S'];
  return (
    <table className={clsx(classes.table, isPortrait && classes.tablePortrait)} cellPadding='0' cellSpacing='0' border='0'>
      <thead>
        <tr>
          {
            directions.map((direction, index) => {
              return (
                <th key={index}><BBTranslate textId={getDirectionTextId(direction)} /></th>
              );
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {
                  row.map((bid, cellIndex) => {
                    const step = getStepNumberFromTable(rows, rowIndex, cellIndex);
                    const bidObject = auction[step];
                    return (
                      <td key={cellIndex}>
                        {
                          bid === ''
                            ? <div style={{ height: '1rem' }} />
                            : <MiniCard
                                size={size}
                                tooltipText={bidObject.comments ? 'Add commentary' : null}
                                suit={bidObject.denomination ? bidObject.denomination : null}
                                value={bidObject.level ? bidObject.level : bidObject.value}
                                hasComment={!!(onBidClick && bidObject.comments)}
                                isBid
                                onClick={() => {
                                  if (onBidClick) {
                                    onBidClick(bidObject, step, rowIndex + 1);
                                  }
                                }}
                              />
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

Auction.propTypes = {
  size: PropTypes.string,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired,
  onBidClick: PropTypes.func
};
