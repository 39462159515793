import React, { useState } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import {
  useNavigate,
  useOutletContext
} from 'react-router-dom';
import { Typography } from '@mui/material';
import { userQueries } from '../../../../graphql/queries';
import { mutations } from '../../../../graphql';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../Components/Store/ConfirmModal.js';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';
import SavingBackdrop from '../../../../Components/Forms/SavingBackdrop.js';
import NoActiveSubscription from './NoActiveSubscriptions.js';
import SubscriptionTable from './SubscriptionsTable.js';

export default function Subscriptions () {
  const { handleSubmitSuccess, handleSubmitError } = useOutletContext();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmType, setConfirmType] = useState('Cancel');
  const [userSubscriptionPlanId, setUserSubscriptionPlanId] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  // get user subscription plans
  const { data, refetch } = useQuery(userQueries.UserSubscriptionPlans, {
    fetchPolicy: 'no-cache'
  });

  const [cancelSubscription] = useMutation(mutations.CancelSubscription);
  const [changeSubscription] = useMutation(mutations.ChangeSubscription);
  const [restoreSubscription] = useMutation(mutations.RestoreSubscription);

  if (!data) {
    return (
      <SavingBackdrop open />
    );
  }

  const confirmTypeData = {
    Cancel: {
      mutation: cancelSubscription,
      confirmMessage: t('are-you-sure-cancel-subscription'),
      successMessage: t('subscription-cancelled')
    },
    Change: {
      mutation: changeSubscription,
      confirmMessage: t('are-you-sure-change-subscription'),
      successMessage: t('subscription-changed-yearly')
    },
    Restore: {
      mutation: restoreSubscription,
      confirmMessage: t('are-you-sure-restore-subscription'),
      successMessage: t('subscription-restored')
    }
  };

  const handleActionClick = (action, userSubscriptionPlanId) => {
    setConfirmOpen(true);
    setConfirmType(action);
    setUserSubscriptionPlanId(userSubscriptionPlanId);
  };

  const handleConfirm = async () => {
    setLoading(true);
    await confirmTypeData[confirmType].mutation({
      variables: {
        userSubscriptionPlanId
      },
      onCompleted: async () => {
        await refetch();
        handleSubmitSuccess(confirmTypeData[confirmType].successMessage);
      },
      onError: (err) => {
        console.log('====> err', err);
        handleSubmitError(err);
      }
    });
    setConfirmOpen(false);
    setLoading(false);
  };

  const handleCancelModal = () => {
    setConfirmOpen(false);
  };

  const handleStoreClick = () => {
    navigate('/subscriptions');
  };

  if (data.userSubscriptionPlans.length === 0) {
    return (
      <NoActiveSubscription onStoreClick={handleStoreClick} />
    );
  }

  const activeSubscriptions = data.userSubscriptionPlans.filter(
    usp => usp.endDateTime === null || moment(usp.endDateTime) > Date.now()
  );

  const expiredSubscriptioms = data.userSubscriptionPlans.filter(
    usp => usp.endDateTime !== null && moment(usp.endDateTime) < Date.now()
  );

  return (
    <>
      {
        activeSubscriptions.length === 0
          ? <NoActiveSubscription onStoreClick={handleStoreClick} />
          : (
            <>
              <Typography variant='h4' sx={{ p: 2 }}>
                <BBTranslate textId='active-subscriptions' />
              </Typography>
              <SubscriptionTable
                userSubscriptionPlans={activeSubscriptions}
                onCancelSubscriptionClick={(uspId) => handleActionClick('Cancel', uspId)}
                onChangeSubscriptionClick={(uspId) => handleActionClick('Change', uspId)}
                onRestoreSubscriptionClick={(uspId) => handleActionClick('Restore', uspId)}
              />
            </>
            )
      }
      {
        expiredSubscriptioms.length > 0 && (
          <>
            <Typography variant='h4' sx={{ p: 2, marginTop: 6 }}>
              <BBTranslate textId='expired-subscriptions' />
            </Typography>
            <SubscriptionTable
              expired
              userSubscriptionPlans={expiredSubscriptioms}
            />
          </>
        )
      }
      <ConfirmModal
        open={confirmOpen}
        title={t('are-you-sure')}
        onConfirmClick={handleConfirm}
        onCancelClick={handleCancelModal}
        cancelLabel={t('no')}
        confirmLabel={t('yes')}
        loading={loading}
      >
        {confirmTypeData[confirmType].confirmMessage}
      </ConfirmModal>
    </>
  );
}
