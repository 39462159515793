import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import {
  Box,
  Chip
} from '@mui/material';
import {
  getShortenedTagName,
  getGroupTagsFromTagsCache,
  getTextIdFromSearchableTag
} from '../../helpers/tag.js';
import { useTranslation } from 'react-i18next';

const ContainerBox = styled(Box)({
  padding: 0,
  marginTop: 5,
  maxHeight: 54,
  overflow: 'hidden'
});

const TagChip = styled(Chip)(({ colour, bordercolour }) => ({
  color: colour,
  borderColor: bordercolour,
  borderRadius: 2,
  marginRight: 5,
  fontSize: '1.4rem',
  marginBottom: 5
}));

const Tags = ({ tags, white }) => {
  const { t } = useTranslation();

  if (!tags) {
    return null;
  }

  tags = getGroupTagsFromTagsCache(tags);

  // get system tags and display
  return (
    <ContainerBox>
      {tags && tags.map(tag => (
        <TagChip
          colour={white ? '#ffffff' : '#000000de'}
          bordercolour={white ? '#ffffff' : '#bdbdbd'}
          key={tag.name}
          size='small'
          label={t(getTextIdFromSearchableTag(getShortenedTagName(tag.name)))}
          variant='outlined'
        />
      ))}
    </ContainerBox>
  );
};

Tags.propTypes = {
  tags: PropTypes.array
};

export default Tags;
