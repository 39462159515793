import React, { useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { dynamoProductQueries } from '../../../../graphql/queries';
import HandDetails from './HandDetails.js';
import {
  isFinalSetInCollection,
  isFinalHandInSet,
  getNextSetInCollection
} from '../../../../helpers/product.js';
import {
  getNextHand,
  formatPathToHandPlay
} from '../../../../helpers/hand.js';
import {
  HandContext,
  UserContext
} from '../../../../Context';
import { useHandAndParentQuery } from '../../../../Hooks';

export default function CollectionHandDetails () {
  let {
    collectionId,
    setId,
    handId,
    language
  } = useParams();

  handId = parseInt(handId);
  collectionId = parseInt(collectionId);
  setId = parseInt(setId);

  const { user } = useContext(UserContext);

  const { init: initHandContext } = useContext(HandContext);

  const queryResponse = useHandAndParentQuery({
    handId,
    parentId: collectionId,
    language,
    parentQuery: dynamoProductQueries.DynamoProductById
  });

  const getNextHandUrl = useCallback((collection, set) => {
    let nextSet = set;
    let nextHand;
    const isFinalHand = isFinalHandInSet(handId, set.hands);
    const isFinalSet = isFinalSetInCollection(set.id, collection.sets);
    if (isFinalHand && !isFinalSet) {
      // is this the final hand in set but NOT the final set in collection
      // ... get next set and get first hand in that set
      nextSet = getNextSetInCollection(set.id, collection.sets);
      nextHand = nextSet.hands[0];
    } else {
      // ... keep the same set but get next hand
      nextHand = getNextHand(handId, set.hands);
    }
    return formatPathToHandPlay(collection.id, nextSet, nextHand);
  }, [handId]);

  useEffect(() => {
    if (queryResponse) {
      const collection = queryResponse.parentData.dynamoProductById;
      const set = collection.sets.find(set => set.id === setId);
      initHandContext({
        hand: queryResponse.handData.handById,
        parent: set,
        grandParent: collection,
        nextHandUrl: getNextHandUrl(collection, set),
        currentUser: user
      });
    }
  }, [queryResponse, initHandContext, getNextHandUrl, user, setId]);

  if (!queryResponse) {
    return null;
  }

  return (
    <HandDetails />
  );
}
