import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  subscriptionQueries,
  priceQueries,
  authorQueries,
  tagQueries
} from '../../../graphql/queries';
import SubscriptionForm from './SubscriptionForm.js';
import Articles from './Articles.js';

const EditSubscription = () => {
  const [authors, setAuthors] = useState();
  const [prices, setPrices] = useState();
  const [tags, setTags] = useState();

  const [getAuthors] = useLazyQuery(authorQueries.Authors, {
    onCompleted: (data) => {
      if (data && data.authors) {
        setAuthors(data.authors);
      }
    },
    onError: (err) => {
      console.log('getAuthors err', err);
    }
  });

  const [getPrices] = useLazyQuery(priceQueries.Prices, {
    onCompleted: (data) => {
      if (data && data.prices) {
        setPrices(data.prices);
      }
    },
    onError: (err) => {
      console.log('getPrices err', err);
    }
  });

  const [getTags] = useLazyQuery(tagQueries.Tags, {
    onCompleted: (data) => {
      if (data && data.tags) {
        setTags(data.tags);
      }
    },
    onError: (err) => {
      console.log('getTags err', err);
    }
  });

  useEffect(() => {
    getAuthors();
    getPrices();
    getTags();
  }, [getAuthors, getPrices, getTags]);

  const { subscriptionId } = useParams();
  const { data, refetch: refetchSubscription } = useQuery(subscriptionQueries.SubscriptionByIdAdmin(subscriptionId), {
    fetchPolicy: 'no-cache'

  });

  if (!data || !authors || !prices) {
    return null;
  }

  const subscription = { ...data.subscriptionByIdAdmin };

  // Getting yearly/monthly price id
  const getPriceIdForPlan = (plans, type) => {
    const plan = plans.find(plan => plan.type === type);
    return plan.price.id;
  };

  subscription.yearlyPriceId = getPriceIdForPlan(subscription.subscriptionPlans, 'Yearly');
  subscription.monthlyPriceId = getPriceIdForPlan(subscription.subscriptionPlans, 'Monthly');

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid xs={6} item>
        <SubscriptionForm
          subscription={subscription}
          authors={authors}
          prices={prices}
          tags={tags}
        />
      </Grid>
      <Grid xs={6} item>
        <Articles
          subscription={subscription}
          refetchSubscription={refetchSubscription}
        />
      </Grid>
    </Grid>
  );
};

export default EditSubscription;
