import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/styles';

const Container = styled(Link)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  maxWidth: 728,
  minHeight: 101,
  width: '100%',
  padding: '6px 110px 6px 18px',
  backgroundColor: '#c03d36',
  backgroundImage: 'url(/img/components/voucher_banner/pattern.svg)',
  position: 'relative',
  margin: '20px auto 0',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 30px'
  }
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  fontWeight: 600,
  color: '#fff',
  fontFamily: 'roc-grotesk, sans-serif',
  lineHeight: 1.3,
  [theme.breakpoints.down('sm')]: {
    paddingRight: '110px'
  }
}));

const Body = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 10
  }
}));

const Icon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 10,
  top: 7,
  zIndex: 0,
  [theme.breakpoints.down('sm')]: {
    top: 20
  }
}));

const VoucherBanner = () => {
  return (
    <Container to='/products/vouchers'>
      <Box sx={{ zIndex: 1, position: 'relative' }}>
        <Heading>The perfect gift is just a <u>click</u> away!</Heading>
        <Body>With a BridgeBee Digital Gift Certificate, they can choose exactly what goes in their library.</Body>
        <Body>It's easy, thoughtful, and instant - Send a gift today!</Body>
      </Box>
      <Icon>
        <img src='/img/components/voucher_banner/logo-click.svg' alt='' />
      </Icon>
    </Container>
  );
};

export default VoucherBanner;
