import React, { useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { dynamoProductQueries } from '../../../../graphql/queries';
import HandDetails from './HandDetails.js';
import {
  getNextHand,
  formatPathToHandPlay
} from '../../../../helpers/hand.js';
import {
  HandContext,
  UserContext
} from '../../../../Context';
import { useHandAndParentQuery } from '../../../../Hooks';

export default function SetHandDetails () {
  let {
    setId,
    handId,
    linkId,
    language,
    embed
  } = useParams();

  handId = parseInt(handId);
  setId = parseInt(setId);

  const { user } = useContext(UserContext);

  const { init: initHandContext } = useContext(HandContext);

  const productQuery = (linkId ? 'DynamoProductByLinkId' : 'DynamoProductById');

  const queryResponse = useHandAndParentQuery({
    handId,
    parentId: setId,
    language,
    parentQuery: dynamoProductQueries[productQuery]
  });

  const getNextHandUrl = useCallback((set) => {
    const nextHand = getNextHand(handId, set.hands);
    if (nextHand) {
      return formatPathToHandPlay(null, set, nextHand);
    }
    return undefined;
  }, [handId]);

  useEffect(() => {
    if (queryResponse) {
      const productData = queryResponse.parentData;
      const set = linkId ? productData.dynamoProductByLinkId : productData.dynamoProductById;
      initHandContext({
        hand: queryResponse.handData.handById,
        parent: set,
        nextHandUrl: getNextHandUrl(set),
        currentUser: user,
        linkId,
        isEmbed: embed
      });
    }
  }, [queryResponse, initHandContext, getNextHandUrl, user, embed, linkId]);

  if (!queryResponse) {
    return null;
  }

  return (
    <HandDetails />
  );
}
