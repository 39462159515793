import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import StoreSelect from '../../../../../Components/Forms/Store/StoreSelect.js';
import ArticleList from './ArticleList.js';
import ArticleCalendar from './ArticleCalendar.js';

const ArticlePicker = (props) => {
  const {
    availableArticles,
    selectedArticleId,
    onArticleClick,
    allowCalendarView
  } = props;

  const handleViewChange = (e) => {
    setSelectedView(e.target.value);
  };

  const { t } = useTranslation();

  const [selectedView, setSelectedView] = useState('list');

  const views = [{
    id: 'list',
    name: t('list-view')
  }, {
    id: 'cal',
    name: t('calendar-view')
  }];

  return (
    <>
      {
        (allowCalendarView) && (
          <FormControl margin='normal' required fullWidth>
            <StoreSelect
              id='view'
              required
              options={views}
              value={selectedView}
              onChange={handleViewChange}
            />
          </FormControl>
        )
      }
      {
        selectedView === 'list'
          ? (
            <ArticleList
              availableArticles={availableArticles}
              selectedArticleId={selectedArticleId}
              onArticleClick={onArticleClick}
            />
            )
          : (
            <ArticleCalendar
              availableArticles={availableArticles}
              selectedArticleId={selectedArticleId}
              onArticleClick={onArticleClick}
            />
            )
      }
    </>
  );
};

export default ArticlePicker;
