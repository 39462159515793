import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { handQueries } from '../../../../graphql/queries';
import {
  Paper,
  Divider,
  Typography,
  Grid,
  Button
} from '@mui/material';
import { mutations } from '../../../../graphql';
import PBNManager from '../PBNs/PBNManager.js';
import AddButton from '../../../../Components/Forms/AddButton.js';
import EditableHandsTable from './EditableHandsTable.js';
import DuplicateHands from './DuplicateHands.js';
import SavingBackdrop from '../../../../Components/Forms/SavingBackdrop';
import { BBSnackbarContext } from '../../../../Components/BBSnackbar/BBSnackbarContext.js';
import { formatPathToAdminHand } from '../../../../helpers/hand.js';
import { UserContext } from '../../../../Context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  header: {
    padding: 25
  },
  table: {
    paddingLeft: 100
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const Hands = ({ parent, parentType, subscription }) => { // parentType will be either 'product' or 'article'
  // state
  const [hands, setHands] = useState(null);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(true);

  // other hooks
  const navigate = useNavigate();
  const classes = useStyles();
  const { openSnackbar } = useContext(BBSnackbarContext);
  const { user } = useContext(UserContext);

  // queries
  const [getHands] = useLazyQuery(handQueries.HandsByParentId, {
    variables: { parentId: parent.id, parentType },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHands(data.handsByParentId);
      setBackdropOpen(false);
    }
  });

  // mutations
  const [upsertHand] = useMutation(mutations.UpsertHand);
  const [reorderHand] = useMutation(mutations.ReorderHand);
  const [deleteHand] = useMutation(mutations.DeleteHand);

  // helper to minimise boiler plate and managing loader/spinner
  const callMutation = async (mutation, args) => {
    setBackdropOpen(true);
    await mutation({ variables: args });
    getHands();
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    const url = formatPathToAdminHand({ hand: null, parent, subscription });
    return navigate(url, { state: { parent, subscription } });
  };

  const handleProcessingComplete = (err) => {
    if (err) {
      openSnackbar(err, 'error');
    } else {
      getHands();
      openSnackbar('new hands added', 'success');
    }
  };

  const handleEditSaveClick = async (id, title) => {
    const vars = { id, title };
    if (parentType === 'product') {
      vars.productId = parent.id;
    } else {
      vars.articleId = parent.id;
    }
    callMutation(upsertHand, vars);
  };

  const handleDeleteClick = async (id) => {
    callMutation(deleteHand, { id });
  };

  const handleApproveRejectClick = (id, status) => {
    callMutation(upsertHand, { id, status });
  };

  const handleReorder = (id, direction) => () => {
    callMutation(reorderHand, { id, direction });
  };

  const handleFreeCheckboxChange = hand => () => {
    callMutation(upsertHand, { id: hand.id, freeToPlay: !hand.freeToPlay });
  };

  const handleDuplicateClick = () => {
    setDuplicateModalOpen(true);
  };

  const handleModalClose = () => {
    setDuplicateModalOpen(false);
  };

  const handleDuplicateSuccess = async () => {
    // reload
    getHands();
    setDuplicateModalOpen(false);
  };

  useEffect(() => {
    getHands();
  }, [getHands]);

  if (!hands) {
    return null;
  }

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant='h5' gutterBottom>
                Hands
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
              >
                <Grid item className={classes.button}>
                  <PBNManager
                    onProcessingComplete={handleProcessingComplete}
                    parentId={parent.id}
                    parentType={parentType}
                  />
                </Grid>
                <Grid item className={classes.button}>
                  <Button
                    onClick={handleDuplicateClick}
                    variant='outlined'
                  >
                    Duplicate hands
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <AddButton
                    onClick={handleAddClick}
                    text='Add hand'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </div>
        <div className={classes.tableWrapper}>
          <EditableHandsTable
            parent={parent}
            subscription={subscription}
            handsArray={hands}
            onEditSaveClick={handleEditSaveClick}
            onDeleteClick={handleDeleteClick}
            onApproveRejectClick={handleApproveRejectClick}
            onFreeCheckboxChange={handleFreeCheckboxChange}
            onReorder={handleReorder}
            currentUser={user}
          />
        </div>
        <div>
          <DuplicateHands
            open={duplicateModalOpen}
            parentType={parentType}
            parentId={parent.id}
            onDuplicateSuccess={handleDuplicateSuccess}
            onModalClose={handleModalClose}
          />
        </div>
      </Paper>
      <SavingBackdrop open={backdropOpen} text='Saving...' />
    </>
  );
};

Hands.propTypes = {
  setId: PropTypes.number.isRequired,
  setTitle: PropTypes.string.isRequired
};

export default Hands;
