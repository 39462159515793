import React from 'react';
import { useMatches, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Breadcrumbs,
  Typography
} from '@mui/material';
import { revertURLTitle } from '../../helpers/http.js';
import BBTranslate from '../Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    padding: '5rem 0 2rem 0',
    fontSize: '1.4rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem'
    }
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  },
  text: {
    color: 'inherit'
  },
  bold: {
    color: 'inherit',
    fontWeight: 'bold'
  }
}));

const BBCrumb = ({ crumb, classes, isFinalCrumb }) => {
  let title = crumb.handle.crumb;
  if (crumb.handle.crumb.includes(':')) {
    // get the matching param from params
    title = revertURLTitle(crumb.params[title.substring(1)]);
  } else {
    title = <BBTranslate textId={title} />;
  }
  if (isFinalCrumb) {
    return (
      <Typography
        className={classes.bold}
        variant='body3'
      >
        {title}
      </Typography>
    );
  }
  return (
    <Link
      className={classes.link}
      to={crumb.pathname}
    >
      <Typography
        className={classes.text}
        variant='body3'
      >
        {title}
      </Typography>
    </Link>
  );
};

const BBBreadcrumbs = () => {
  const matches = useMatches();

  // get all matches that contain a crumb
  const crumbs = matches.filter(
    (match) => Boolean(match.handle?.crumb)
  );

  // if route specifies a crumb colour, use that
  let crumbColour = 'inherit';
  if (crumbs.length) {
    crumbColour = crumbs[crumbs.length - 1].handle.crumbColour;
  }

  const classes = useStyles();

  return (
    <Breadcrumbs
      className={classes.breadcrumbs}
      style={{ color: crumbColour }}
      aria-label='breadcrumb'
    >
      <Link className={classes.link} to='/'>
        <Typography
          className={classes.text}
          variant='body3'
        >
          <BBTranslate textId='home' />
        </Typography>
      </Link>
      {
        crumbs.map((crumb, index) => {
          return (
            <BBCrumb
              key={index}
              crumb={crumb}
              classes={classes}
              isFinalCrumb={crumbs.length - 1 === index}
            />
          );
        })
      }
    </Breadcrumbs>
  );
};

export default BBBreadcrumbs;
